import React, { lazy, Suspense, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./protectedRoute";
import Loader from "components/shared/Loader/Loader";
import ChatSupport from "pages/auth/chat/ChatSupport";
import LottiLoader from "components/shared/Loader/LottiLoader";
import LazyLoader from "components/shared/Loader/LazyLoader";
import OrderTrackNew from "pages/order/orderTrack/OrderTrackNew";

const Home = lazy(() => import("pages/home/Home"));
const Restaurants = lazy(() => import("pages/restaurants/Restaurants"));
const Login = lazy(() => import("pages/auth/login/Login"));
const Logout = lazy(() => import("pages/logout/Logout"));
const Forceupdate = lazy(() => import("pages/auth/force-update/Forceupdate"));
const SignUpGuest = lazy(() => import("pages/auth/signup-guest/SignUpGuest"));
const DeleteAccount = lazy(() =>
  import("pages/Sidebar/myAccount/delete-account/delete-account")
);
//const ChatSupport = lazy(() => import("pages/auth/chat/ChatSupport"));
const MasterLogin = lazy(() => import("pages/auth/masterPassword/MasterLogin"));
const CheckBalance = lazy(() =>
  import("components/footer/checkBalance/CheckBalance")
);
const CardCheckout = lazy(() =>
  import("pages/Sidebar/PurchaseGiftCard/CardCheckout")
);
const RedeemPoints = lazy(() => import("pages/Sidebar/rewards/RedeemPoints"));
const SocialLoginVerifyOtp = lazy(() =>
  import("pages/auth/socialAuthentication/SocialLoginVerifyOtp")
);
const SocialSignup = lazy(() =>
  import("pages/auth/socialAuthentication/SocialSignup")
);
const PaymentMethod = lazy(() =>
  import("pages/Sidebar/paymentMethod/PaymentMethod")
);
const GiftCardCheckout = lazy(() =>
  import("../pages/Sidebar/giftCardCheckout/GiftCardCheckout")
);
const GiftCard = lazy(() => import("pages/Sidebar/giftCard/GiftCard"));
const Rewards = lazy(() => import("pages/Sidebar/rewards/Rewards"));
const BuyGiftCard = lazy(() =>
  import("pages/Sidebar/PurchaseGiftCard/BuyGiftCard")
);
const AddEditAddress = lazy(() =>
  import("pages/Sidebar/myAccount/addresses/AddEditAddress")
);
const Addresses = lazy(() =>
  import("pages/Sidebar/myAccount/addresses/Addresses")
);
const ChangePassword = lazy(() =>
  import("pages/Sidebar/myAccount/ChangePassword")
);
const MyAccount = lazy(() => import("pages/Sidebar/myAccount/MyAccount"));
const RestaurantsCheckout = lazy(() =>
  import("pages/restaurants/restaurantsCheckout/RestaurantsCheckout")
);
const OrderDetail = lazy(() => import("pages/order/orderDetail/OrderDetail"));
const Order = lazy(() => import("pages/order/Order"));
const Notification = lazy(() =>
  import("pages/Sidebar/notification/Notification")
);
const RestaurantsDetail = lazy(() =>
  import("pages/restaurants/restaurantsMenu/RestaurantsMenuPage")
);
const ContactUs = lazy(() => import("pages/contactus/ContactUs"));
const AfterLogin = lazy(() => import("pages/restaurants/AfterLogin"));
const PrivacyPolicy = lazy(() => import("pages/privacyPolicy/PrivacyPolicy"));
const Faq = lazy(() => import("pages/faq/Faq"));
const Career = lazy(() => import("pages/career/Career"));
const SignupOtpVerfication = lazy(() =>
  import("pages/auth/signupOtpVerfication/SignupOtpVerfication")
);
const SignUp = lazy(() => import("pages/auth/signup/SignUp"));
const RestaurantSignup = lazy(() =>
  import("pages/auth/restaurantSignup/RestaurantSignup")
);
const ResetPassword = lazy(() =>
  import("pages/auth/resetPassword/ResetPassword")
);
const OtpVerification = lazy(() =>
  import("pages/auth/otpVerification/OtpVerification")
);
const ForgotPassword = lazy(() =>
  import("pages/auth/forgotpassword/ForgotPassword")
);
const DriverSignup = lazy(() => import("pages/auth/driverSignup/DriverSignup"));
const NotFoundRoute = lazy(() => import("components/404/NotFoundRoute"));

export const FoodDudesRoutes = (props) => {
  return (
    <Routes>
      {/* PUBLIC ROUTES */}
      <Route
        exact
        path="/"
        element={
          <Suspense fallback={<></>}>
            <Home />
          </Suspense>
        }
      />
      <Route
        exact
        path="/sign-in"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        exact
        path="/logout"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <Logout />
          </Suspense>
        }
      />
      <Route
        exact
        path="/force-update"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <Forceupdate />
          </Suspense>
        }
      />
      <Route
        exact
        path="/sign-up"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <SignUp />
          </Suspense>
        }
      />
      <Route
        exact
        path="/guest"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <SignUpGuest />
          </Suspense>
        }
      />
      <Route
        exact
        path="/contact-us"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <ContactUs />
          </Suspense>
        }
      />
      <Route
        exact
        path="/career"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <Career />
          </Suspense>
        }
      />
      <Route
        exact
        path="/forgot-password"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <ForgotPassword />
          </Suspense>
        }
      />
      <Route
        exact
        path="/otp-verification"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <OtpVerification />
          </Suspense>
        }
      />
      <Route
        exact
        path="/reset-password"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <ResetPassword />
          </Suspense>
        }
      />
      <Route
        exact
        path="/privacy-policy"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <PrivacyPolicy />
          </Suspense>
        }
      />
      <Route
        exact
        path="/terms-of-use"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <PrivacyPolicy />
          </Suspense>
        }
      />
      <Route
        exact
        path="/restaurant-signup"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <RestaurantSignup />
          </Suspense>
        }
      />
      <Route
        exact
        path="/driver-signup"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <DriverSignup />
          </Suspense>
        }
      />
      <Route exact path="/chat" element={<ChatSupport />} />
      <Route
        exact
        path="/faq"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <Faq />
          </Suspense>
        }
      />
      <Route
        exact
        path="/restaurants"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <Restaurants />
          </Suspense>
        }
      />

      <Route
        exact
        path=":city"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <Restaurants />
          </Suspense>
        }
      />
      <Route
        path=":city/:resName"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <RestaurantsDetail />
          </Suspense>
        }
      />
      <Route
        exact
        path="/checkout"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <RestaurantsCheckout />
          </Suspense>
        }
      />
      <Route
        exact
        path="/signup-otp-verification"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <SignupOtpVerfication />
          </Suspense>
        }
      />
      <Route
        exact
        path="/:id/social-signup"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <SocialSignup />
          </Suspense>
        }
      />
      <Route
        exact
        path="/:id/login-otp-verification"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <SocialLoginVerifyOtp />
          </Suspense>
        }
      />
      <Route
        exact
        path="/check-balance"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <CheckBalance />
          </Suspense>
        }
      />
      <Route
        exact
        path="/master-login/:token"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <MasterLogin />
          </Suspense>
        }
      />
      <Route
        exact
        path="/order/track/:id"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <OrderTrackNew />
            {/* <OrderTrack /> */}
          </Suspense>
        }
      />

      {/* PRIVATE ROUTES */}
      <Route
        path="/after-login"
        element={
          <ProtectedRoute>
            <Suspense fallback={<LazyLoader loader={true} />}>
              <AfterLogin />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/my-account"
        element={
          <ProtectedRoute>
            <Suspense fallback={<LazyLoader loader={true} />}>
              <MyAccount />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/change-password"
        element={
          <ProtectedRoute>
            <Suspense fallback={<LazyLoader loader={true} />}>
              <ChangePassword />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/delete-account"
        element={
          <ProtectedRoute>
            <Suspense fallback={<LazyLoader loader={true} />}>
              <DeleteAccount />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/addresses"
        element={
          <ProtectedRoute>
            <Suspense fallback={<LazyLoader loader={true} />}>
              <Addresses />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/address/:name"
        element={
          <ProtectedRoute>
            <Suspense fallback={<LazyLoader loader={true} />}>
              <AddEditAddress />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/order"
        element={
          <ProtectedRoute>
            <Suspense fallback={<LazyLoader loader={true} />}>
              <Order />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/order-detail/:id"
        element={
          <ProtectedRoute>
            <Suspense fallback={<LazyLoader loader={true} />}>
              <OrderDetail />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/buy-gift-card"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <BuyGiftCard />
          </Suspense>
        }
      />
      <Route
        path="/rewards"
        element={
          <ProtectedRoute>
            <Suspense fallback={<LazyLoader loader={true} />}>
              <Rewards />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/redeem-points"
        element={
          <ProtectedRoute>
            <Suspense fallback={<LazyLoader loader={true} />}>
              <RedeemPoints />
            </Suspense>
          </ProtectedRoute>
        }
      />

      <Route
        path="/card-checkout"
        element={
          <Suspense fallback={<LazyLoader loader={true} />}>
            <CardCheckout />
          </Suspense>
        }
      />

      <Route
        path="/giftcard"
        element={
          <ProtectedRoute>
            <Suspense fallback={<LazyLoader loader={true} />}>
              <GiftCard />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/giftcard-checkout"
        element={
          <ProtectedRoute>
            <Suspense fallback={<LazyLoader loader={true} />}>
              <GiftCardCheckout />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/payment-method"
        element={
          <ProtectedRoute>
            <Suspense fallback={<LazyLoader loader={true} />}>
              <PaymentMethod />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/notification"
        element={
          <ProtectedRoute>
            <Suspense fallback={<LazyLoader loader={true} />}>
              <Notification />
            </Suspense>
          </ProtectedRoute>
        }
      />

      <Route path="*" element={<NotFoundRoute />}></Route>
    </Routes>
  );
};
