import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import "../chat/chat.css";
import "react-phone-input-2/lib/style.css";

import ReactHelmet from "components/shared/ReactHelmet/ReactHelmet";
import LottiLoader from "components/shared/Loader/LottiLoader";

const ChatSupport = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {}, []);
  return (
    <div className="main-container">
      <ReactHelmet />
      {loading && (
        <Container className=" d-flex align-items-center default-back">
          <LottiLoader loader={true} />
        </Container>
      )}
      <section className="partner-with-us py-lg-5 py-3">
        <Row>
          <Col lg={2}></Col>
          <Col lg={8} sm={12} md={12} xs={12}>
            <iframe
              id="chat-support"
              title="Chat Support"
              onload="window.parent.scrollTo(0,0)"
              allowtransparency="true"
              className="chat-support-form"
              allowfullscreen="true"
              allow="geolocation; microphone; camera"
              src="https://tawk.to/chat/54fa63c38ac92cc0041d1a42/default"
              frameborder="0"
              onLoad={() => setLoading(false)}
            />
          </Col>
          <Col lg={2}></Col>
        </Row>
      </section>
    </div>
  );
};

export default ChatSupport;
