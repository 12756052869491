import axios from "axios";
import { axiosError } from "./errorHandler";
import { BASE_URL } from "./path";
import * as Sentry from "@sentry/react";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("access_token");
  //config.headers.Authorization = token ? `Bearer ${token}` : "";

  const guestToken = sessionStorage.getItem("__g_access_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else if (guestToken) {
    config.headers.Authorization = `Bearer ${guestToken}`;
  } else {
    config.headers.Authorization = ``;
  }
  return config;
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status !== 422) {
      Sentry.captureException(JSON.stringify(err.response.data));
    }
    axiosError(err);
  }
);

export { axiosInstance };
