const GET_CAREER = (data, callback) => {
  return {
    type: "GET_CAREER",
    payload: data,
    callback,
  };
};

const GET_NOTIFICATION = (data, callback) => {
  return {
    type: "GET_NOTIFICATION",
    payload: data,
    callback,
  };
};
const GET_USER_PROFILE = (data, callback) => {
  return {
    type: "GET_USER_PROFILE",
    payload: data,
    callback,
  };
};
const SET_USER_PROFILE = (data) => {
  return {
    type: "SET_USER_PROFILE",
    payload: data,
  };
};
const GET_SUBSCRIBE = (data) => {
  return {
    type: "GET_SUBSCRIBE",
    payload: data,
  };
};
const GET_UPDATE_PROFILE = (data, callback) => {
  return {
    type: "GET_UPDATE_PROFILE",
    payload: data,
    callback,
  };
};

const GET_USER_PROFILE_FOR_ORDER_CHECKOUT = (data, callback) => {
  return {
    type: "GET_USER_PROFILE_FOR_ORDER_CHECKOUT",
    payload: data,
    callback,
  };
};

export {
  GET_CAREER,
  GET_NOTIFICATION,
  GET_USER_PROFILE,
  SET_USER_PROFILE,
  GET_SUBSCRIBE,
  GET_UPDATE_PROFILE,
  GET_USER_PROFILE_FOR_ORDER_CHECKOUT,
};
