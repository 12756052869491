const GET_CUISINES_DATA = (data, callback) => {
  return {
    type: "GET_CUISINES_DATA",
    payload: data,
    callback,
  };
};

const SET_CUISINES_DATA = (data) => {
  return {
    type: "SET_CUISINES_DATA",
    payload: data,
  };
};

const GET_RESTAURANT_LIST = (data, callback) => {
  return {
    type: "GET_RESTAURANT_LIST",
    payload: data,
    callback,
  };
};

const SET_RESTAURANT_LIST = (data) => {
  return {
    type: "SET_RESTAURANT_LIST",
    payload: data,
  };
};

const GET_ASAP_AVAILABILITY = (data, callback) => {
  return {
    type: "GET_ASAP_AVAILABILITY",
    payload: data,
    callback,
  };
};

const SET_ASAP_AVAILABILITY = (data) => {
  return {
    type: "SET_ASAP_AVAILABILITY",
    payload: data,
  };
};

const GET_SERVICE_TYPE = (data) => {
  return {
    type: "GET_SERVICE_TYPE",
    payload: data,
  };
};
const GET_ASAP_ADVANCE_TYPE = (data) => {
  return {
    type: "GET_ASAP_ADVANCE_TYPE",
    payload: data,
  };
};
const GET_DELIVER_PICKUP_TAB = (data) => {
  return {
    type: "GET_DELIVER_PICKUP_TAB",
    payload: data,
  };
};
const GET_DELIVERY_PICKUP_NOW_HIT = (data) => {
  return {
    type: "GET_DELIVERY_PICKUP_NOW_HIT",
    payload: data,
  };
};
const ASAP_DATE = (data) => {
  return {
    type: "ASAP_DATE",
    payload: data,
  };
};
const GET_CITY_TIME_SLOT = (data, callback) => {
  return {
    type: "GET_CITY_TIME_SLOT",
    payload: data,
    callback,
  };
};
const SET_CITY_TIME_SLOT = (data) => {
  return {
    type: "SET_CITY_TIME_SLOT",
    payload: data,
  };
};

const GET_TIME = (data) => {
  return {
    type: "GET_TIME",
    payload: data,
  };
};
const SCHEDULE_ASAP = (data) => {
  return {
    type: "SCHEDULE_ASAP",
    payload: data,
  };
};
const GET_SELECTED_RESTAURANT_DATA = (data) => {
  return {
    type: "GET_SELECTED_RESTAURANT_DATA",
    payload: data,
  };
};
const GET_SELECT_CUISINES_SEARCH = (data) => {
  return {
    type: "GET_SELECT_CUISINES_SEARCH",
    payload: data,
  };
};

export {
  GET_CUISINES_DATA,
  SET_CUISINES_DATA,
  GET_RESTAURANT_LIST,
  SET_RESTAURANT_LIST,
  GET_SERVICE_TYPE,
  GET_ASAP_ADVANCE_TYPE,
  GET_DELIVER_PICKUP_TAB,
  GET_DELIVERY_PICKUP_NOW_HIT,
  ASAP_DATE,
  GET_CITY_TIME_SLOT,
  SET_CITY_TIME_SLOT,
  GET_TIME,
  SCHEDULE_ASAP,
  GET_SELECTED_RESTAURANT_DATA,
  GET_SELECT_CUISINES_SEARCH,
  SET_ASAP_AVAILABILITY,
  GET_ASAP_AVAILABILITY,
};
