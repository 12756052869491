import { textTruncateMore } from 'helpers/textTruncate/TextTruncate'
import React, { useState } from 'react'
import LottiLoader from 'components/shared/Loader/LottiLoader'
import { useDispatch, useSelector } from 'react-redux'
import {
  SHOW_MENU_BOTTOM_NAVIGATION_VISIBLE
} from 'store/Action/RasturantMenuAction'
import AddonsModal from 'components/restaurants/Attributes/AddonsModal'
import { GET_DATE_TIME_ON_MENU } from 'store/Action/AuthAction'

const RestaurantMenuSearch = ({
  loader,
  menuList = [],
  setShowAddonsModal,
  showAddonsModal
}) => {
  const dispatch = useDispatch()
  // All Selectors
  const { SHOW_MODAL } = useSelector(store => ({
    SHOW_MODAL: store.RasturantMenuReducer.showAddonsModalOnHeaderSearch
  }))
  // ALL STATE
  const [activeProductId, setActiveProductId] = useState('')
  const [activeProductName, setActiveProductName] = useState('')
  const [activeProduct, setActiveProduct] = useState({})

  const handelShowModal = item => {
    setActiveProductId(item?.products_id)
    setActiveProductName(item?.product_name)
    setActiveProduct(item)
    // dispatch(SHOE_ADDONS_MODAL_ON_HEADER_SEARCH(!SHOW_MODAL))
    setShowAddonsModal(!showAddonsModal)
  }

  // useEffect(() => {
  //   // dispatch(SHOE_ADDONS_MODAL_ON_HEADER_SEARCH(false))
  // }, [])

  const handelShowDateAndTimeOnMobile = () => {
    dispatch(GET_DATE_TIME_ON_MENU('showDateTime'))
  }

  return (
    <div>
      <div>
        <div className='search-reasturant-listing position-absolute top-0 w-100'>
          {loader ? (
            <span className='text-center border-0 pb-3'>
              <LottiLoader loader={true} />
            </span>
          ) : (
            <ul>
              {menuList &&
                menuList?.length !== 0 &&
                menuList?.map((men, index) => {
                  return (
                    <li
                      className=''
                      key={men?.products_id}
                      onClick={() => {
                        if (men?.is_available == true) {
                          handelShowModal(men)
                        } else {
                          handelShowDateAndTimeOnMobile()
                          dispatch(SHOW_MENU_BOTTOM_NAVIGATION_VISIBLE(false))
                        }
                      }}
                      style={
                        men?.is_available == false
                          ? { cursor: 'not-allowed' }
                          : { cursor: 'pointer' }
                      }
                    >
                      <div>
                        <div className='product_search_list d-flex align-items-center'>
                          {men?.products_image && (
                            <div className='position-relative'>
                              <div className='pro_im'>
                                <img src={men?.products_image} alt='No_Image' />
                              </div>
                              {/* {men?.is_available == false && (
                                <div className="close-restra position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center rounded-3">
                                  <strong>Closed</strong>
                                </div>
                              )} */}
                            </div>
                          )}

                          <div className='search-product-list-title'>
                            <h2>{textTruncateMore(men?.products_name, 50)}</h2>
                            <p>
                              {textTruncateMore(men?.products_description, 50)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })}
            </ul>
          )}
        </div>
      </div>
      {showAddonsModal && (
        <AddonsModal
          show={showAddonsModal}
          onHide={handelShowModal}
          productName={activeProductName}
          activeProduct={activeProduct}
        />
      )}
    </div>
  )
}

export default RestaurantMenuSearch
