const SET_PURCHASE_CARD_SELECTION = (data) => {
  return {
    type: "SET_PURCHASE_CARD_SELECTION",
    payload: data,
  };
};
const GET_PURCHASE_AMOUNT = (data, callback) => {
  return {
    type: "GET_PURCHASE_AMOUNT",
    payload: data,
    callback,
  };
};
const SET_PURCHASE_AMOUNT = (data) => {
  return {
    type: "SET_PURCHASE_AMOUNT",
    payload: data,
  };
};

const GET_ADD_UPDATE_PURCHASE_CARD_TO_CART = (data, callback) => {
  return {
    type: "GET_ADD_UPDATE_PURCHASE_CARD_TO_CART",
    payload: data,
    callback,
  };
};
const SET_WALLET_PURCHASE_GIFT_CARD_SERIES = (data) => {
  return {
    type: "SET_WALLET_PURCHASE_GIFT_CARD_SERIES",
    payload: data,
  };
};
const SET_PURCHASE_PAYMENT_OPTION = (data) => {
  return {
    type: "SET_PURCHASE_PAYMENT_OPTION",
    payload: data,
  };
};

const GET_PURCHASE_PLACE_ORDER = (data, callback) => {
  return {
    type: "GET_PURCHASE_PLACE_ORDER",
    payload: data,
    callback,
  };
};
const SET_PURCHASE_PLACE_ORDER = (data) => {
  return {
    type: "SET_PURCHASE_PLACE_ORDER",
    payload: data,
  };
};
const SET_ACTIVE_PURCHASE_GIFT_CARD = (data) => {
  return {
    type: "SET_ACTIVE_PURCHASE_GIFT_CARD",
    payload: data,
  };
};
const SET_NO_ORDER_MODAL = (data) => {
  return {
    type: "SET_NO_ORDER_MODAL",
    payload: data,
  };
};
const GET_PURCHASE_GIFT_CARD_PRICE_COUNT = (data, callback) => {
  return {
    type: "GET_PURCHASE_GIFT_CARD_PRICE_COUNT",
    payload: data,
    callback,
  };
};
const SET_PURCHASE_GIFT_CARD_PRICE_COUNT = (data, callback) => {
  return {
    type: "SET_PURCHASE_GIFT_CARD_PRICE_COUNT",
    payload: data,
    callback,
  };
};

export {
  SET_PURCHASE_CARD_SELECTION,
  GET_PURCHASE_AMOUNT,
  SET_PURCHASE_AMOUNT,
  GET_ADD_UPDATE_PURCHASE_CARD_TO_CART,
  SET_WALLET_PURCHASE_GIFT_CARD_SERIES,
  SET_PURCHASE_PAYMENT_OPTION,
  GET_PURCHASE_PLACE_ORDER,
  SET_PURCHASE_PLACE_ORDER,
  SET_ACTIVE_PURCHASE_GIFT_CARD,
  SET_NO_ORDER_MODAL,
  GET_PURCHASE_GIFT_CARD_PRICE_COUNT,
  SET_PURCHASE_GIFT_CARD_PRICE_COUNT,
};
