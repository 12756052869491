export const urls = [
  "",
  "sign-in",
  "logout",
  "force-update",
  "sign-up",
  "guest",
  "contact-us",
  "career",
  "forgot-password",
  "otp-verification",
  "reset-password",
  "privacy-policy",
  "terms-of-use",
  "restaurant-signup",
  "driver-signup",
  "chat",
  "faq",
  "checkout",
  "signup-otp-verification",
  "check-balance",
  "after-login",
  "my-account",
  "change-password",
  "delete-account",
  "addresses",
  "order",
  "order-detail",
  "buy-gift-card",
  "rewards",
  "redeem-points",
  "card-checkout",
  "giftcard",
  "giftcard-checkout",
  "payment-method",
  "notification",
];
