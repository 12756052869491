import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import restaurantImg from "assets/images/icon-resturant.svg";
import addressImg from "assets/images/icon-address.svg";
import OrderDetailCurveMap from "components/shared/Map/OrderDetailCurveMap";

const OrderDetailMap = ({ ORDER_DETAILS }) => {
  const dispatch = useDispatch();

  //ALL SELECTORS
  //   const { SERVICE_TYPE, DEFAULT_ADDRESS } = useSelector((store) => ({
  //     SERVICE_TYPE: store.ResturantReducer.serviceType,
  //     DEFAULT_ADDRESS: store.ProfileReducer.defaultAddress,
  //   }));

  // ALL STATE
  const [mapValue, setMapValue] = useState(null);
  const [mapProjection, setMapProjection] = useState(null);
  const [mapZoom, setMapZoom] = useState(10);

  const selectedAddress = {
    lat:
      ORDER_DETAILS !== undefined && ORDER_DETAILS?.customers_lat
        ? ORDER_DETAILS?.customers_lat
        : 0,
    lng:
      ORDER_DETAILS !== undefined && ORDER_DETAILS?.customers_lng
        ? ORDER_DETAILS?.customers_lng
        : 0,
  };

  const restaurantAddress = {
    lat: ORDER_DETAILS?.restaurant_latitude
      ? parseFloat(ORDER_DETAILS?.restaurant_latitude)
      : 0,
    lng: ORDER_DETAILS?.restaurant_longitude
      ? parseFloat(ORDER_DETAILS?.restaurant_longitude)
      : 0,
  };

  let cities = [];

  if (
    ORDER_DETAILS?.mode == "Delivery" &&
    restaurantAddress &&
    selectedAddress
  ) {
    cities = [
      {
        img: restaurantImg,
        id: 1,
        name: "rest",
        position: {
          lat: restaurantAddress?.lat ? parseFloat(restaurantAddress?.lat) : 0,
          lng: restaurantAddress?.lng ? parseFloat(restaurantAddress?.lng) : 0,
        },
      },
      {
        img: addressImg,
        id: 2,
        name: "addr",
        position: {
          lat: selectedAddress?.lat ? parseFloat(selectedAddress?.lat) : 0,
          lng: selectedAddress?.lng ? parseFloat(selectedAddress?.lng) : 0,
        },
      },
    ];
  } else {
    if (restaurantAddress) {
      cities = [
        {
          img: restaurantImg,
          id: 1,
          name: "rest",
          position: {
            lat: restaurantAddress?.lat
              ? parseFloat(restaurantAddress?.lat)
              : 0,
            lng: restaurantAddress?.lng
              ? parseFloat(restaurantAddress?.lng)
              : 0,
          },
        },
      ];
    }
  }

  const handleOnLoad = (map) => {
    setMapValue(map);
    const bounds = new window.google.maps.LatLngBounds();
    cities.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
    window.google.maps.event.addListenerOnce(
      map,
      "projection_changed",
      function () {
        setMapProjection(map.getProjection());
      }
    );
    window.google.maps.event.addListener(map, "zoom_changed", function () {
      setMapZoom(map.getZoom());
    });
  };

  const options = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    paths:
      ORDER_DETAILS.mode == "Delivery"
        ? [cities[0].position, cities[1].position]
        : [cities[0].position, cities[0].position],
    zIndex: 1,
  };

  return (
    <Fragment>
      <OrderDetailCurveMap
        isCheckOut
        cities={cities}
        handleOnLoad={handleOnLoad}
        dispatch={dispatch}
        polylineOptions={
          (ORDER_DETAILS.mode == "Delivery") == 1 ? options : false
        }
        mapProjection={mapProjection}
        mapZoom={mapZoom}
        mapValue={mapValue}
        ORDER_DETAILS={ORDER_DETAILS}
      />
    </Fragment>
  );
};

export default OrderDetailMap;
