import React from "react";
import Lottie from "react-lottie";
import Loader from "assets/lottiAnimation/Loader.json";

export default function LazyLoader({ loader }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    //animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <span className={loader ? "lazy-page-loading-new" : "w-100"}>
      <Lottie
        options={defaultOptions}
        height={120}
        width={120}
        style={{ marginTop: "130px" }}
      />
    </span>
  );
}
