const initialState = {
  usedGiftCards: [],
  giftCardBalance: {},
};

const GiftCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ALL_USED_GIFT_CARD":
      return {
        ...state,
        usedGiftCards: action.payload,
      };
    case "SET_CHECK_GIFT_CARD_BAL":
      return {
        ...state,
        giftCardBalance: action.payload,
      };

    default:
      return state;
  }
};

export default GiftCardReducer;
