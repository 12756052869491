const GET_STATE_INFO = (data) => {
  return {
    type: "GET_STATE_INFO",
    payload: data,
  };
};

const SET_STATE_INFO = (data) => {
  return {
    type: "SET_STATE_INFO",
    payload: data,
  };
};

const GET_DEFAULT_ADDRESS = (data) => {
  return {
    type: "GET_DEFAULT_ADDRESS",
    payload: data,
  };
};

const GET_SUBSCRIPTION = (data, callback) => {
  return {
    type: "GET_SUBSCRIPTION",
    payload: data,
    callback,
  };
};
const GET_CMS = (params, callback) => {
  return {
    type: "GET_CMS",
    payload: params,
    callback,
  };
};
const GET_CONTACT_US = (data, callback) => {
  return {
    type: "GET_CONTACT_US",
    payload: data,
    callback,
  };
};
const GET_FAQ = (data, callback) => {
  return {
    type: "GET_FAQ",
    payload: data,
    callback,
  };
};

const SHOW_SIDE_BAR = (data) => {
  return {
    type: "SHOW_SIDE_BAR",
    payload: data,
  };
};
const GET_RESTAURANT_LIST_HEADER = (data, callback) => {
  return {
    type: "GET_RESTAURANT_LIST_HEADER",
    payload: data,
    callback,
  };
};
const SET_RESTAURANT_LIST_HEADER = (data) => {
  return {
    type: "SET_RESTAURANT_LIST_HEADER",
    payload: data,
  };
};

const GET_CITY_DETAILS = (data, callback) => {
  return {
    type: "GET_CITY_DETAILS",
    payload: data,
    callback,
  };
};

const SET_CITY_DETAILS = (data) => {
  return {
    type: "SET_CITY_DETAILS",
    payload: data,
  };
};

const SHOW_BOTTOM_NAVIGATION_SEARCH = (data) => {
  return {
    type: "SHOW_BOTTOM_NAVIGATION_SEARCH",
    payload: data,
  };
};
const GET_HEADER_NOTIFICATION = (data, callback) => {
  return {
    type: "GET_HEADER_NOTIFICATION",
    payload: data,
    callback,
  };
};
const SET_HEADER_NOTIFICATION = (data) => {
  return {
    type: "SET_HEADER_NOTIFICATION",
    payload: data,
  };
};
const GET_NOTIFICATION_POPUP = (data) => {
  return {
    type: "GET_NOTIFICATION_POPUP",
    payload: data,
  };
};

const GET_ACTIVE_ACCOUNT = (data) => {
  return {
    type: "GET_ACTIVE_ACCOUNT",
    payload: data,
  };
};

const GET_SHOW_RESTAURANT_CART = (data) => {
  return {
    type: "GET_SHOW_RESTAURANT_CART",
    payload: data,
  };
};
const GET_AUTO_APPLIED_COUPON = (data) => {
  return {
    type: "GET_AUTO_APPLIED_COUPON",
    payload: data,
  };
};
const SHOW_TICKER = (data) => {
  return {
    type: "SHOW_TICKER",
    payload: data,
  };
};
const SHOW_TICKER_TEXT = (data) => {
  return {
    type: "SHOW_TICKER_TEXT",
    payload: data,
  };
};
const GET_CITY_SEO_DATA = (data) => {
  return {
    type: "GET_CITY_SEO_DATA",
    payload: data,
  };
};
const GET_UPDATE_LAT_LNG = (data, callback) => {
  return {
    type: "GET_UPDATE_LAT_LNG",
    payload: data,
    callback,
  };
};
const GET_ADDRESS_BOOK_ID = (data, callback) => {
  return {
    type: "GET_ADDRESS_BOOK_ID",
    payload: data,
    callback,
  };
};
const GET_NOTIFICATION_EXIST = (data, callback) => {
  return {
    type: "GET_NOTIFICATION_EXIST",
    payload: data,
    callback,
  };
};
const SET_NOTIFICATION_EXIST = (data, callback) => {
  return {
    type: "SET_NOTIFICATION_EXIST",
    payload: data,
    callback,
  };
};
const SET_NOTIFICATION_READ = (data, callback) => {
  return {
    type: "SET_NOTIFICATION_READ",
    payload: data,
    callback,
  };
};

export {
  SET_STATE_INFO,
  GET_STATE_INFO,
  GET_SUBSCRIPTION,
  GET_DEFAULT_ADDRESS,
  GET_CMS,
  GET_CONTACT_US,
  GET_FAQ,
  SHOW_SIDE_BAR,
  GET_RESTAURANT_LIST_HEADER,
  SET_RESTAURANT_LIST_HEADER,
  SHOW_BOTTOM_NAVIGATION_SEARCH,
  GET_HEADER_NOTIFICATION,
  SET_HEADER_NOTIFICATION,
  GET_NOTIFICATION_POPUP,
  GET_ACTIVE_ACCOUNT,
  GET_SHOW_RESTAURANT_CART,
  GET_AUTO_APPLIED_COUPON,
  SHOW_TICKER,
  SHOW_TICKER_TEXT,
  GET_CITY_SEO_DATA,
  GET_UPDATE_LAT_LNG,
  GET_ADDRESS_BOOK_ID,
  GET_NOTIFICATION_EXIST,
  SET_NOTIFICATION_EXIST,
  SET_NOTIFICATION_READ,
  SET_CITY_DETAILS,
  GET_CITY_DETAILS
};
