import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const Token = localStorage.getItem("access_token");
  const { GROUP_ID } = useSelector((store) => ({
    GROUP_ID: store?.RasturantMenuReducer?.groupId,
  }));
  if (!Token) {
    // user is not authenticated
    const isMobileApp = localStorage.getItem("is_mobile_app");
    if (isMobileApp === "yes") {
      return <Navigate to="/logout" />;
    } else {
      return <Navigate to={`/sign-in?=group_id${GROUP_ID}`} />;
    }
  }
  return children;
};
