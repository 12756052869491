import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import menuIcon from "assets/images/menu-icon.svg";
import menuIconmobile from "assets/images/menu-iconmobile.svg";
import Logo from "assets/images/logo-new.webp";
import DateAndTimePopup from "components/asap/DateAndTimePopup";
import SelectPrimaryAddress from "components/asap/SelectPrimaryAddress";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Search from "./HeaderSearch/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  ASAP_DATE,
  GET_ASAP_ADVANCE_TYPE,
  GET_DELIVERY_PICKUP_NOW_HIT,
  GET_DELIVER_PICKUP_TAB,
  GET_SELECTED_RESTAURANT_DATA,
  GET_SELECT_CUISINES_SEARCH,
  GET_SERVICE_TYPE,
  GET_TIME,
  SET_CUISINES_DATA,
} from "store/Action/RasturantAction";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_DEFAULT_ADDRESS,
  GET_RESTAURANT_LIST_HEADER,
  GET_SHOW_RESTAURANT_CART,
  GET_UPDATE_LAT_LNG,
  SHOW_BOTTOM_NAVIGATION_SEARCH,
} from "store/Action/HomeAction";
import NotFound from "./HeaderSearch/NotFound";
import moment from "moment";
import {
  GET_ALL_ADDRESS,
  GET_ALL_ADDRESS_ORDER_CHECKOUT,
  GET_PRIMARY_ADDRESS,
} from "store/Action/Sidebar/AddressAction";
import { CircularProgress } from "@mui/material";
import { textTruncateMore } from "helpers/textTruncate/TextTruncate";
import RestaurantMenuSearch from "./HeaderSearch/RestaurantMenuSearch";
import { publicIpv4 } from "public-ip";
import {
  GET_CART_LIST,
  GET_RESTAURANT_MENU_LIST_HEADER,
  PREVENT_RESTAURANT_ID,
  PREVENT_RESTAURANT_NAME,
  SHOW_MENU_BOTTOM_NAVIGATION_VISIBLE,
} from "store/Action/RasturantMenuAction";
import ClearIcon from "@mui/icons-material/Clear";
import shoppingIcon from "assets/images/shopping-bag-icon.svg";
import HeaderCart from "./headerCart/HeaderCart";
import { GET_DATE_TIME_ON_MENU } from "store/Action/AuthAction";
import FloatingActionContainer from "components/shared/Floating/FloatingActionContainer";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddonsModal from "components/restaurants/Attributes/AddonsModal";
import FastfoodOutlinedIcon from "@mui/icons-material/FastfoodOutlined";
import { GOOGLE_MAP_API_KEY } from "services/path";

const AuthHeader = ({
  classes,
  path,
  sidebarShow,
  handelSidebarOpen,
  url,
  handleToggleHeaderCart,
  setHeaderCart,
  headerCart,
  showHamburger,
  visibleFloatingMessage,
  dynamicUrl,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // var restaurantId = location.pathname?.replace(/^\D+/g, "");

  const Token = localStorage.getItem("access_token");
  const pathRoute = location.pathname.replace("/", "");

  //ALL SELECTORS
  const {
    DEFAULT_ADDRESS,
    SERVICE_TYPE,
    RESTAURANTS_SEARCH_LIST,
    SHOW_SEARCH_SCREEN,
    ASAP_ADVANCE,
    DELIVERY_OR_PICKUP,
    ASAP_TIME,
    ASAP_DATE_STATE,
    SCHEDULE_ASAP,
    ALL_ADDRESS,
    RESTAURANT_MENU_SEARCH_LIST,
    SHOW_SEARCH_MENU_SCREEN,
    NEW_USER_STATE,
    CART_LIST,
    DATE_MODAL_POP,
    SELECTED_RESTAURANT_DATA_STATE,
    SHOW_TICKER_STATE,
    MENU,
    CUISINES,
    ACTIVE_MENU_STATE,
    CLEAR_MENU_VAL,
    CUISINES_SELECT_SEARCH_STATE,
    SHOW_MENU_MODAL,
    SUB_MENU_SEARCH_STATE,
    ASAP_ADVANCE_STATE,
    ASAP_TIME_STATE,
    GROUP_ID,
    ASAP_AVAILABILITY,
  } = useSelector((store) => ({
    DEFAULT_ADDRESS: store.ProfileReducer.defaultAddress,
    RESTAURANTS_SEARCH_LIST: store.HomeReducer.restaurantsOnSearch,
    SERVICE_TYPE: store.ResturantReducer.serviceType,
    ASAP_ADVANCE: store?.ResturantReducer?.asapAdvance,
    SHOW_SEARCH_SCREEN: store.HomeReducer.showSearchScreen,
    DELIVERY_OR_PICKUP: store?.ResturantReducer?.deliverOrPickup,
    ASAP_DATE_STATE: store?.ResturantReducer?.asapDate,
    ASAP_TIME: store?.ResturantReducer?.timeSlot,
    SCHEDULE_ASAP: store?.ResturantReducer?.scheduleAsap,
    ALL_ADDRESS: store.AddressReducer.allAddress,
    RESTAURANT_MENU_SEARCH_LIST:
      store.RasturantMenuReducer.menuDetailsHeaderSearch ?? [],
    SHOW_SEARCH_MENU_SCREEN:
      store.RasturantMenuReducer.showBottomNavigationSearchScreen,
    NEW_USER_STATE: store?.AuthReducer?.newUserRegistered,
    CART_LIST: store?.RasturantMenuReducer?.cartList ?? [],
    DATE_MODAL_POP: store?.AuthReducer?.showDateTimePop ?? "",
    SELECTED_RESTAURANT_DATA_STATE:
      store?.ResturantReducer?.selectedRestaurantData ?? {},
    SHOW_TICKER_STATE: store?.HomeReducer?.showTickerMessage ?? false,
    CUISINES: store?.ResturantReducer?.cuisines,
    MENU: store.RasturantMenuReducer.menuDetails.menu ?? [],
    ACTIVE_MENU_STATE: store?.RasturantMenuReducer?.activeMenu ?? "",
    CLEAR_MENU_VAL: store?.RasturantMenuReducer ?? "",
    CUISINES_SELECT_SEARCH_STATE:
      store?.ResturantReducer?.searchCusinesArr ?? [],
    SUB_MENU_SEARCH_STATE: store?.RasturantMenuReducer?.searchSubMenu ?? [],
    SHOW_MENU_MODAL: store.RasturantMenuReducer.showAddonsModalOnHeaderSearch,
    ASAP_ADVANCE_STATE: store?.ResturantReducer?.asapAdvance,
    ASAP_TIME_STATE: store?.ResturantReducer?.timeSlot,
    GROUP_ID: store?.RasturantMenuReducer?.groupId,
    ASAP_AVAILABILITY: store?.ResturantReducer?.asapAvailability,
  }));

  var restaurantId = SELECTED_RESTAURANT_DATA_STATE?.id;
  //var dynamicUrl = `/${SELECTED_RESTAURANT_DATA_STATE.city_name}/${SELECTED_RESTAURANT_DATA_STATE?.restro_name}`;
  var dynamicUrl = `/${SELECTED_RESTAURANT_DATA_STATE?.slug}`;

  // ALL STATE
  const [showAsapPopup, setShowAsapPopup] = useState(false);
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const [restaurantsValue, setRestaurantsValue] = useState("");
  const [restaurantLoader, setRestaurantLoader] = useState(false);
  const [deliverAndPickupBtnHit, setDeliveryAndPickupHit] = useState(0);
  const [addressLoading, setAddressLoading] = useState(false);
  const [menuSearchValue, setMenuSearchValue] = useState("");
  const [currentLANIp, setCurrentLANIp] = useState("");
  const [restturantMenuSerchLoader, setRestturantMenuSerchLoader] =
    useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [primeryAddressLoader, setPrimeryAddressLoader] = useState(false);
  const [subMenuData, setSubMenuData] = useState([]);
  const [cusionsArr, setCusionsArr] = useState([]);
  const [activeProductId, setActiveProductId] = useState("");
  const [activeProductName, setActiveProductName] = useState("");
  const [activeProduct, setActiveProduct] = useState({});
  const [showAddonsModal, setShowAddonsModal] = useState(false);
  const [showAddonsSubModal, setShowAddonsSubModal] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const toggleServiceType = (type) => {
    dispatch(GET_DELIVER_PICKUP_TAB(type));
    if (type == "delivery") {
      if (ASAP_DATE_STATE && ASAP_TIME?.time) {
        dispatch(GET_SERVICE_TYPE(1));
        dispatch(GET_ASAP_ADVANCE_TYPE(2));
      } else {
        dispatch(GET_SERVICE_TYPE(1));
        dispatch(GET_ASAP_ADVANCE_TYPE(1));
      }
    } else {
      if (ASAP_DATE_STATE && ASAP_TIME?.time) {
        dispatch(GET_SERVICE_TYPE(2));
        dispatch(GET_ASAP_ADVANCE_TYPE(2));
      } else {
        dispatch(GET_SERVICE_TYPE(2));
        dispatch(GET_ASAP_ADVANCE_TYPE(1));
      }
    }
  };

  // useEffect(() => {
  //   if (ASAP_DATE_STATE) {
  //     var ToDate = new Date();
  //     ToDate.setDate(ToDate.getDate() - 1);
  //     if (new Date(ASAP_DATE_STATE).getTime() < ToDate.getTime()) {
  //       dispatch(ASAP_DATE(new Date()));
  //     }
  //   }
  // }, [ASAP_DATE_STATE]);

  const handelSearch = (event) => {
    setRestaurantsValue(event.target.value);
  };
  const handelMenuSearch = (event) => {
    setMenuSearchValue(event.target.value);
  };
  useEffect(() => {
    if (restaurantsValue) {
      if (Object.keys(ASAP_TIME).length) {
        var currentDate = moment(ASAP_DATE_STATE).format("YYYY-MM-DD");
        var time = ASAP_TIME?.time?.split("-");
        var Time = time[1]?.replace(/\s/g, "");
        // var currentTime = moment(Time, "h:mm:ss A").format("HH:mm:ss");
        var currentTime = moment(Time, "h:mm A").format("HH:mm");
      }
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (DEFAULT_ADDRESS?.entry_city) {
        const params = {
          lat_from: DEFAULT_ADDRESS?.address_lat,
          lng_from: DEFAULT_ADDRESS?.address_lng,
          keyword: restaurantsValue,
          asap_or_advance: ASAP_ADVANCE,
          service_type: SERVICE_TYPE,
          date: currentDate ?? "",
          time: currentTime ?? "",
          timezone: timeZone,
          city: DEFAULT_ADDRESS?.entry_city,
        };
        dispatch(
          GET_RESTAURANT_LIST_HEADER({ params }, (loading, error) => {
            setRestaurantLoader(loading);
          })
        );
      }
    }
  }, [restaurantsValue]);

  useEffect(() => {
    currentLANip();
    dispatch(SHOW_BOTTOM_NAVIGATION_SEARCH(false));
    dispatch(SHOW_MENU_BOTTOM_NAVIGATION_VISIBLE(false));
  }, []);

  useEffect(() => {
    // dispatch(GET_SERVICE_TYPE(1));
    // dispatch(GET_ASAP_ADVANCE_TYPE(1));
    handelCallCart();
    dispatch(GET_DELIVER_PICKUP_TAB("delivery"));
    dispatch(GET_DELIVERY_PICKUP_NOW_HIT(0));
  }, []);

  const handelDelverOrPickupNow = () => {
    setShowAsapPopup(false);
    setShowAddressPopup(false);
    setDeliveryAndPickupHit(deliverAndPickupBtnHit + 1);
    dispatch(GET_DELIVERY_PICKUP_NOW_HIT(deliverAndPickupBtnHit + 1));
    dispatch(GET_ASAP_ADVANCE_TYPE(1));
    dispatch(ASAP_DATE(""));
    dispatch(GET_TIME({}));
    dispatch(GET_DATE_TIME_ON_MENU(""));
  };

  useEffect(() => {
    if (Token) {
      dispatch(
        GET_ALL_ADDRESS({}, (loading, error) => {
          setAddressLoading(loading);
        })
      );
    }
  }, [Token]);

  const handelPrimaryAddress = (item) => {
    const perviousAddress = Object.assign({}, DEFAULT_ADDRESS);
    dispatch(
      GET_PRIMARY_ADDRESS(item?.address_book_id, (loading, error) => {
        setPrimeryAddressLoader(true);
        if (!error) {
          dispatch(
            GET_ALL_ADDRESS_ORDER_CHECKOUT({}, (res, loading, errorNew) => {
              if (!errorNew) {
                if (res?.length !== 0) {
                  if (res[0]?.is_default_address == 1) {
                    dispatch(GET_DEFAULT_ADDRESS(res[0]));
                    dispatch(
                      GET_ALL_ADDRESS({}, (loading, error) => {
                        setPrimeryAddressLoader(false);
                        setShowAddressPopup(false);
                        setAddressLoading(loading);
                      })
                    );
                  } else if (res[0]?.is_default_address == 0) {
                    setPrimeryAddressLoader(false);
                    setShowAddressPopup(false);
                  }
                }
                if (window.location.path !== "/restaurants") {
                  if (perviousAddress.entry_postcode !== item.entry_postcode) {
                    navigate("/restaurants");
                  }
                }
                console.log("innnnnnnnn");
              }
            })
          );
        }
      })
    );
  };

  // useEffect(() => {
  //   if (Token) {
  //     if (ALL_ADDRESS?.length !== 0) {
  //       if (ALL_ADDRESS[0]?.is_default_address == 1) {
  //         dispatch(GET_DEFAULT_ADDRESS(ALL_ADDRESS[0]));
  //         setShowAddressPopup(false);
  //       }
  //     }
  //   }
  // }, [addressLoading]);

  const handelOnSelectRestaurants = (item) => {
    // navigate(`/restaurants-menu/${item?.id}`);
    dispatch(GET_SELECTED_RESTAURANT_DATA(item));
    navigate(`/${item?.slug}?q=fd`);
  };

  const currentLANip = async () => {
    try {
      const LAN_IP = await publicIpv4();
      setCurrentLANIp(LAN_IP);
    } catch (e) {}
  };

  useEffect(() => {
    if (menuSearchValue && currentLANIp) {
      if (Object.keys(ASAP_TIME).length) {
        var currentDate = moment(ASAP_DATE_STATE).format("YYYY-MM-DD");
        var time = ASAP_TIME?.time?.split("-");
        var Time = time[1]?.replace(/\s/g, "");
        // var currentTime = moment(Time, "h:mm:ss A").format("HH:mm:ss");
        var currentTime = moment(Time, "h:mm A").format("HH:mm");
      }
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const params = {
        ip_address: currentLANIp ?? "",
        restaurant_id: restaurantId ?? "",
        service_type: SERVICE_TYPE,
        asap_or_advance: ASAP_ADVANCE,
        timezone: timeZone,
        date: currentDate ?? "",
        time: currentTime ?? "",
        keyword: menuSearchValue,
        city: DEFAULT_ADDRESS?.entry_city,
      };

      dispatch(
        GET_RESTAURANT_MENU_LIST_HEADER({ params }, (loading, error) => {
          // setRestaurantLoader(loading);
          setRestturantMenuSerchLoader(loading);
        })
      );
    }
  }, [menuSearchValue]);

  useEffect(() => {
    if (DATE_MODAL_POP == "showDateTime") {
      setShowAsapPopup(true);
    } else {
      setShowAsapPopup(false);
    }
  }, [DATE_MODAL_POP]);

  useEffect(() => {
    if (Object.keys(DEFAULT_ADDRESS)?.length) {
      fetchLatitudeAndLongitude();
    }
  }, [DEFAULT_ADDRESS]);

  const fetchLatitudeAndLongitude = () => {
    //    console.log('Address', DEFAULT_ADDRESS)

    if (parseFloat(DEFAULT_ADDRESS?.address_lat) == 0) {
      const API_KEY = GOOGLE_MAP_API_KEY;
      const address = `${DEFAULT_ADDRESS?.entry_street_address},${DEFAULT_ADDRESS?.entry_city},${DEFAULT_ADDRESS?.entry_state},${DEFAULT_ADDRESS?.entry_postcode}`;
      fetch(
        `${process.env.REACT_APP_BASE_API_URL}get-geocode-details?address=${address}`
      )
        .then((res) => res.json())
        .then((response) => {
          if (response?.results?.length) {
            if (Object.keys(response?.results[0]?.geometry?.location).length) {
              const latitude = response?.results[0]?.geometry?.location?.lat;
              const longitude = response?.results[0]?.geometry?.location?.lng;
              const addressBookId = DEFAULT_ADDRESS?.address_book_id;
              // const streetName = response?.results[0]?.address_components
              //   ?.length
              //   ? response?.results[0]?.address_components[0]?.long_name +
              //     ' ' +
              //     response?.results[0]?.address_components[1]?.long_name +
              //     ' ' +
              //     response?.results[0]?.address_components[3]?.long_name
              //   : ''
              const streetName = response?.results[0]?.address_components
                ?.length
                ? response?.results[0]?.address_components[0]?.long_name +
                  " " +
                  response?.results[0]?.address_components[1]?.long_name
                : "";
              // const streetName = DEFAULT_ADDRESS?.entry_street_address
              const stateName = response?.results[0]?.address_components[4]
                ?.long_name
                ? response?.results[0]?.address_components[4]?.long_name
                : response?.results[0]?.address_components[2]?.long_name;
              // console.log('latitude', latitude, 'longitude', longitude)
              // const stateName = DEFAULT_ADDRESS?.entry_state
              console.log("response?.results[0]?.address_components", response);

              handelUpdateLatAndLng(
                latitude,
                longitude,
                addressBookId,
                streetName,
                stateName
              );
            }
          }
        })
        .catch((status) => {
          console.log("Request failed.  Returned status of", status);
        });
    }
  };

  const handelUpdateLatAndLng = (
    latitude,
    longitude,
    addressBookId,
    streetName,
    stateName
  ) => {
    const dataPayload = {
      address_lat: latitude,
      address_lng: longitude,
      address_book_id: addressBookId,
      entry_street_name: streetName,
      entry_state: stateName,
    };
    dispatch(
      GET_UPDATE_LAT_LNG(dataPayload, (loading, error) => {
        if (!error) {
          const data = {
            ...DEFAULT_ADDRESS,
            address_lat: latitude,
            address_latitude: latitude,
            address_lng: longitude,
            address_longitude: longitude,
            entry_street_name: streetName,
            entry_state: stateName,
          };
          dispatch(GET_DEFAULT_ADDRESS(data));
        }
      })
    );
  };

  useEffect(() => {
    if (location.pathname != dynamicUrl) {
      setMenuSearchValue("");
    }
    if (location.pathname != "/restaurants") {
      setRestaurantsValue("");
    }
  }, [location.pathname]);

  const handelSelectCuisines = (item) => {
    // const temp = [...cusionsArr]
    const temp = [...CUISINES_SELECT_SEARCH_STATE];
    if (CUISINES_SELECT_SEARCH_STATE.some((el) => el == item?.id)) {
      let res = temp.filter((el) => {
        if (el !== item?.id) {
          return el;
        }
      });

      // setCusionsArr(res)
      dispatch(GET_SELECT_CUISINES_SEARCH(res));
    } else {
      // setCusionsArr([...temp, item.id])
      dispatch(GET_SELECT_CUISINES_SEARCH([...temp, item.id]));
    }
    let CUISINESLIST = CUISINES.filter((CUISINE) => CUISINE.id !== item.id);
    CUISINESLIST.unshift(item);
    dispatch(SET_CUISINES_DATA(CUISINESLIST));
    dispatch(SHOW_BOTTOM_NAVIGATION_SEARCH(!SHOW_SEARCH_SCREEN));
  };

  const handelOnMenuSearchClick = (item) => {
    setActiveProductId(item?.products_id);
    setActiveProductName(item?.product_name);
    setActiveProduct(item);
    setShowAddonsSubModal(!showAddonsSubModal);
    // dispatch(SHOE_ADDONS_MODAL_ON_HEADER_SEARCH(!SHOW_MENU_MODAL))
    dispatch(SHOW_MENU_BOTTOM_NAVIGATION_VISIBLE(false));
  };

  const handelShowDateAndTimeOnMobile = () => {
    dispatch(GET_DATE_TIME_ON_MENU("showDateTime"));
  };

  const handelCallCart = () => {
    if (ASAP_ADVANCE_STATE == 1) {
      if (DEFAULT_ADDRESS?.entry_city) {
        const params = {
          group_id: GROUP_ID,
          lat_from: DEFAULT_ADDRESS?.address_lat ?? 0,
          lng_from: DEFAULT_ADDRESS?.address_lng ?? 0,
          postcode: DEFAULT_ADDRESS?.entry_postcode ?? "",
          asap_or_advance: ASAP_ADVANCE_STATE,
          service_type: SERVICE_TYPE,
          city: DEFAULT_ADDRESS?.entry_city,
        };
        dispatch(
          GET_CART_LIST({ params }, (res, loading, error) => {
            setCartLoading(loading);
            if (res?.restaurant) {
              dispatch(PREVENT_RESTAURANT_NAME(res?.restaurant?.name));
              dispatch(PREVENT_RESTAURANT_ID(res?.restaurant));
            }
          })
        );
      }
    } else {
      if (DEFAULT_ADDRESS?.entry_city) {
        const currentDate = moment(ASAP_DATE_STATE).format("YYYY-MM-DD");
        const time = ASAP_TIME_STATE?.time?.split("-");
        const Time = time[1]?.replace(/\s/g, "");
        const currentTime = moment(Time, "h:mm A").format("HH:mm");
        const params = {
          group_id: GROUP_ID,
          lat_from: DEFAULT_ADDRESS?.address_lat ?? 0,
          lng_from: DEFAULT_ADDRESS?.address_lng ?? 0,
          postcode: DEFAULT_ADDRESS?.entry_postcode ?? "",
          asap_or_advance: ASAP_ADVANCE_STATE,
          date: currentDate,
          time: currentTime,
          service_type: SERVICE_TYPE,
          city: DEFAULT_ADDRESS?.entry_city,
        };
        dispatch(
          GET_CART_LIST({ params }, (res, loading, error) => {
            if (error) {
              setCartLoading(false);
            } else {
              setCartLoading(loading);
            }
            console.log("Loading", loading, res, error);
            if (res?.restaurant) {
              dispatch(PREVENT_RESTAURANT_NAME(res?.restaurant?.name));
              dispatch(PREVENT_RESTAURANT_ID(res?.restaurant));
            }
          })
        );
      }
    }
  };

  const [asapLabel, setAsapLabel] = useState("ASAP");
  useEffect(() => {
    let asapLabel = "";
    if (!ASAP_AVAILABILITY && !ASAP_TIME?.time) {
      asapLabel = "Schedule";
    } else {
      asapLabel = ASAP_TIME?.time
        ? moment(ASAP_DATE_STATE).format("ddd") +
          ", " +
          moment(ASAP_DATE_STATE).format("MMM D") +
          ", " +
          ASAP_TIME?.time
        : "ASAP";
    }
    setAsapLabel(asapLabel);
  }, [ASAP_AVAILABILITY, ASAP_DATE_STATE, ASAP_TIME?.time]);

  //const [totalCartItem,setTotalCartItem]=
  const totalCartItem = () => {
    let total = 0;
    for (const cart of CART_LIST) {
      total += cart.customers_basket_quantity;
    }

    return total;
  };

  return (
    <div>
      <header
        className={classes}
        style={
          !path
            ? {}
            : {
                boxShadow: "0px 0.071rem 0.71rem 0px rgb(0 0 0 / 20%)",
                backgroundColor: "#fff",
                animation: "none",
                position: "fixed",
              }
        }
      >
        {SHOW_TICKER_STATE && visibleFloatingMessage && (
          <FloatingActionContainer />
        )}
        <div
          className={
            showHamburger ? "front-header" : "front-header without-hamburger"
          }
        >
          <Container fluid>
            <div
              className={
                !path
                  ? "d-flex justify-content-between align-items-center front-header-sec"
                  : "d-flex  justify-content-between align-items-center"
              }
            >
              <div className="header-wrapper d-flex align-items-center">
                <div className="main-logo d-flex align-items-center">
                  {path && sidebarShow ? (
                    <Button
                      className="hamburger_btn d-inline-block me-sm-4 me-2 bg-transparent border-0 p-0"
                      onClick={() => handelSidebarOpen()}
                    >
                      {/* {!VISIBLE_SIDEBAR &&  */}
                      <img
                        src={menuIcon}
                        alt="No_Image"
                        className="d-none d-md-inline-block for_desktop"
                      />
                      <img
                        src={menuIconmobile}
                        alt="No_Image"
                        className="d-inline-block d-md-none for_mobile"
                      />
                      {/* } */}
                    </Button>
                  ) : (
                    <span className="d-inline-block me-sm-4 me-2 bg-transparent border-0 p-0" />
                  )}

                  {NEW_USER_STATE == "newUser" ? (
                    <img src={Logo} alt="No_Image" />
                  ) : (
                    <>
                      <Link
                        to={
                          location.pathname == dynamicUrl ? "/restaurants" : "/"
                        }
                        onClick={() =>
                          dispatch(GET_SHOW_RESTAURANT_CART(false))
                        }
                      >
                        <img src={Logo} alt="No_Image" />
                      </Link>
                    </>
                  )}
                </div>

                <div className="ps-sm-4 ps-1 d-flex align-items-center">
                  <div className="nav-tabs d-flex align-items-center">
                    <Button
                      className={`${
                        SERVICE_TYPE === 1
                          ? "selection-tabs active"
                          : "selection-tabs"
                      }`}
                      onClick={() => {
                        toggleServiceType("delivery");
                        setShowAsapPopup(false);
                        setShowAddressPopup(false);
                      }}
                    >
                      Delivery
                    </Button>
                    <Button
                      className={`${
                        SERVICE_TYPE === 2
                          ? "selection-tabs active"
                          : "selection-tabs"
                      }`}
                      onClick={() => {
                        toggleServiceType("pickup");
                        setShowAsapPopup(false);
                        setShowAddressPopup(false);
                      }}
                    >
                      Pickup
                    </Button>
                  </div>

                  <div
                    className={
                      SHOW_TICKER_STATE
                        ? "timeandaddress d-sm-flex text-center align-items-center position-lg-relative time_hide_mobile-new ticker-visible"
                        : "timeandaddress d-sm-flex text-center align-items-center position-lg-relative time_hide_mobile-new"
                    }
                  >
                    {/* {url == "/restaurants-menu/" && ( */}
                    {location.pathname == dynamicUrl && (
                      <span className="d-inline-block d-lg-none back-btn rest-debit-wrapper position-absolute start-0 ps-2 top-50 translate-middle-y">
                        <ArrowBackIcon
                          onClick={() => {
                            dispatch(GET_SHOW_RESTAURANT_CART(false));
                            navigate("/restaurants");
                          }}
                        />{" "}
                      </span>
                    )}

                    <div className="choose-date-flex">
                      <Button
                        className="time-btn time_hide_mobile fw-bold"
                        onClick={() => {
                          setShowAsapPopup(true);
                          setShowAddressPopup(false);
                        }}
                      >
                        <>
                          {asapLabel}
                          <span className="d-inline-block d-lg-none">
                            <KeyboardArrowDownIcon />
                          </span>
                        </>
                      </Button>
                      {showAsapPopup && (
                        <DateAndTimePopup
                          setShowAsapPopup={setShowAsapPopup}
                          setShowAddressPopup={setShowAddressPopup}
                          handelDelverOrPickupNow={handelDelverOrPickupNow}
                          // handelSelectTimeSlot={handelSelectTimeSlot}
                        />
                      )}
                    </div>

                    <Button className="to time-btn d-sm-block d-none">
                      to
                    </Button>

                    <div className="choose-time-flex">
                      {/* <span className=" menu-icons">
                        <img src={locationIcon} alt="No_Image" className="location-icon-header" />
                      </span> */}
                      <Button
                        className="time-btn fw-bold px-2"
                        onClick={() => {
                          setShowAsapPopup(false);
                          setShowAddressPopup(true);
                        }}
                        // title={DEFAULT_ADDRESS?.entry_street_address}
                        title={DEFAULT_ADDRESS?.entry_street_name}
                      >
                        {/* <span className="d-inline-block d-lg-none timeandaddress_color me-1 me-sm-2">
                          <GpsFixedIcon />
                        </span> */}
                        {addressLoading ?? (
                          <CircularProgress
                            size={15}
                            style={{
                              zIndex: "10",
                              color: "#F06F00",
                              marginLeft: "13px",
                              background: "#ffffff",
                            }}
                          />
                        )}{" "}
                        {/* {textTruncateMore(
                          DEFAULT_ADDRESS?.entry_street_address,
                          15
                        )} */}
                        {textTruncateMore(
                          DEFAULT_ADDRESS?.entry_street_name
                            ? DEFAULT_ADDRESS?.entry_street_name?.replace(
                                ",",
                                ""
                              )
                            : DEFAULT_ADDRESS?.entry_city?.replace(",", ""),
                          50
                        )}
                        <span className="d-inline-block d-lg-none">
                          <KeyboardArrowDownIcon />
                        </span>
                      </Button>
                      {showAddressPopup && (
                        <SelectPrimaryAddress
                          setShowAsapPopup={setShowAsapPopup}
                          setShowAddressPopup={setShowAddressPopup}
                          allAddress={ALL_ADDRESS}
                          addressLoading={addressLoading}
                          handelPrimaryAddress={handelPrimaryAddress}
                          primeryAddressLoader={primeryAddressLoader}
                          windowWidth={windowSize.innerWidth}
                          setPrimeryAddressLoader={setPrimeryAddressLoader}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="search-login-wrapper d-flex align-items-center">
                {/* {url == "/restaurants-menu/" ? ( */}
                {location?.pathname.toLowerCase()?.toLowerCase() ===
                dynamicUrl?.toLowerCase() ? (
                  <div
                    className={
                      SHOW_SEARCH_MENU_SCREEN
                        ? `search-reasturants position-relative me-lg-4 me-2 search-rest-mobile show-search`
                        : "search-reasturants position-relative me-lg-4 me-2 search-rest-mobile "
                    }
                  >
                    <Button
                      className="search-icon mobile_search_back d-block d-md-none"
                      onClick={() =>
                        dispatch(SHOW_MENU_BOTTOM_NAVIGATION_VISIBLE(false))
                      }
                    >
                      <ArrowBackIcon />
                    </Button>
                    <Form className="position-relative mobile_search_ss">
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          placeholder="Search Food"
                          value={menuSearchValue}
                          onChange={(e) => handelMenuSearch(e)}
                        />
                        {menuSearchValue && (
                          <Button
                            className="cross-icon position-absolute top-50  translate-middle-y bg-transparent border-0 text-dark p-0"
                            onClick={() => setMenuSearchValue("")}
                          >
                            <ClearIcon className="" />
                          </Button>
                        )}
                        <Button className="search-icon position-absolute top-50 translate-middle-y bg-transparent border-0 text-dark p-0">
                          <SearchIcon className="" />
                        </Button>
                      </div>
                    </Form>

                    {menuSearchValue && (
                      <>
                        {RESTAURANT_MENU_SEARCH_LIST.length !== 0 ? (
                          <RestaurantMenuSearch
                            menuList={RESTAURANT_MENU_SEARCH_LIST}
                            loader={restturantMenuSerchLoader}
                            setShowAddonsModal={setShowAddonsModal}
                            showAddonsModal={showAddonsModal}
                          />
                        ) : (
                          <NotFound
                            loader={restturantMenuSerchLoader}
                            menu={"menu"}
                          />
                        )}
                      </>
                    )}
                    {!menuSearchValue && (
                      <div className="top-search-cuisines d-md-none d-block px-sm-4 px-2 mt-4">
                        {/* <h6>Sub Menu</h6> */}

                        {SUB_MENU_SEARCH_STATE &&
                          SUB_MENU_SEARCH_STATE?.length > 0 &&
                          SUB_MENU_SEARCH_STATE?.map((item, i) => {
                            return (
                              <div key={i}>
                                <h6>{item?.menu_title ?? ""}</h6>
                                <ul>
                                  {item?.products &&
                                    item?.products?.length > 0 &&
                                    item?.products?.map((data) => {
                                      return (
                                        <li
                                          className="search-sty smash-data-mobile"
                                          onClick={() => {
                                            if (data?.is_available) {
                                              handelOnMenuSearchClick(data);
                                            } else {
                                              handelShowDateAndTimeOnMobile();
                                              dispatch(
                                                SHOW_MENU_BOTTOM_NAVIGATION_VISIBLE(
                                                  false
                                                )
                                              );
                                            }
                                          }}
                                        >
                                          {data?.products_image ? (
                                            <div
                                              className="position-relative"
                                              onClick={() =>
                                                handelOnMenuSearchClick()
                                              }
                                            >
                                              <div className="pro_im">
                                                <img
                                                  src={data?.products_image}
                                                  alt="No_Image"
                                                  className="search-menu-img-dev"
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="pro_im">
                                              {" "}
                                              <FastfoodOutlinedIcon />
                                            </div>
                                          )}
                                          {/* <FastfoodIcon /> */}
                                          <span>
                                            {textTruncateMore(
                                              data?.products_name,
                                              50
                                            ) ?? ""}
                                          </span>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className={
                      SHOW_SEARCH_SCREEN
                        ? `search-reasturants position-relative me-lg-4 me-2 search-rest-mobile show-search`
                        : "search-reasturants position-relative me-lg-4 me-2 search-rest-mobile "
                    }
                  >
                    <Button
                      className="back-icon mobile_search_back d-block d-md-none"
                      onClick={() =>
                        dispatch(SHOW_BOTTOM_NAVIGATION_SEARCH(false))
                      }
                    >
                      <ArrowBackIcon />
                    </Button>
                    <Form className="position-relative mobile_search_ss">
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          placeholder="Search Restaurants"
                          autoFocus={true}
                          value={restaurantsValue}
                          onChange={(e) => handelSearch(e)}
                        />
                        {restaurantsValue && (
                          <Button
                            className="cross-icon position-absolute translate-middle-y bg-transparent border-0 text-dark p-0"
                            onClick={() => setRestaurantsValue("")}
                          >
                            <ClearIcon className="" />
                          </Button>
                        )}
                        <Button className="search-icon position-absolute top-50 translate-middle-y bg-transparent border-0 text-dark p-0">
                          <SearchIcon className="" />
                        </Button>
                      </div>
                    </Form>
                    {restaurantsValue && (
                      <>
                        {RESTAURANTS_SEARCH_LIST?.length !== 0 ? (
                          <Search
                            restaurantList={RESTAURANTS_SEARCH_LIST}
                            loader={restaurantLoader}
                            handelOnSelectRestaurants={(item) =>
                              handelOnSelectRestaurants(item)
                            }
                          />
                        ) : (
                          <NotFound loader={restaurantLoader} />
                        )}
                      </>
                    )}

                    {!restaurantsValue && (
                      <div className="top-search-cuisines d-md-none d-block px-sm-4 px-3 mt-4">
                        <div className="d-flex justify-content-between align-item-center">
                          <h6>Cuisines</h6>
                          <p
                            className="mb-0 clear-search"
                            onClick={() => {
                              setCusionsArr([]);
                              dispatch(GET_SELECT_CUISINES_SEARCH([]));
                              dispatch(
                                SHOW_BOTTOM_NAVIGATION_SEARCH(
                                  !SHOW_SEARCH_SCREEN
                                )
                              );
                            }}
                          >
                            Clear All
                          </p>
                        </div>
                        <ul>
                          {CUISINES &&
                            CUISINES?.length > 0 &&
                            CUISINES?.map((item, i) => {
                              return (
                                <li
                                  className="search-sty justify-content-between"
                                  key={item?.id}
                                  onClick={() => handelSelectCuisines(item)}
                                >
                                  <div>
                                    <RestaurantIcon />
                                    <span>{item?.title}</span>
                                  </div>
                                  {CUISINES_SELECT_SEARCH_STATE?.some(
                                    (el) => el == item?.id
                                  ) ? (
                                    <CheckCircleIcon
                                      sx={{ float: "right", color: "#f06f00" }}
                                    />
                                  ) : null}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    )}
                  </div>
                )}

                <div>
                  {!path ? (
                    <div className="header-login-btn">
                      <Link
                        to={`/sign-in?group_id=${GROUP_ID}`}
                        className="theme-btn sign-in-btn"
                      >
                        Sign In
                      </Link>
                      <Link
                        to={`/sign-up?group_id=${GROUP_ID}`}
                        className="theme-btn"
                      >
                        Sign Up
                      </Link>
                    </div>
                  ) : (
                    <>
                      {sidebarShow && (
                        <div className="d-md-block d-none">
                          <div
                            className="d-flex header-cart justify-content-center"
                            style={
                              // url != `/restaurants-menu/`
                              location?.pathname?.toLowerCase() !==
                              dynamicUrl?.toLowerCase()
                                ? { padding: "7px 27px" }
                                : { padding: "7px 27px", cursor: "not-allowed" }
                            }
                            onClick={() => {
                              // if (
                              //   CART_LIST?.length &&
                              //   url != `/restaurants-menu/`
                              // ) {
                              if (
                                CART_LIST?.length &&
                                location?.pathname?.toLowerCase() !==
                                  dynamicUrl?.toLowerCase()
                              ) {
                                handleToggleHeaderCart();
                              }
                            }}
                          >
                            <img src={shoppingIcon} alt="No_Image" />
                            {cartLoading ? (
                              <i
                                className="fa fa-circle-o-notch fa-spin"
                                style={{
                                  fontSize: "24px",
                                  color: "#fff",
                                  marginLeft: "10px",
                                }}
                              ></i>
                            ) : (
                              <>
                                <span>{totalCartItem()}</span>
                              </>
                            )}
                          </div>
                          {headerCart && CART_LIST?.length !== 0 && (
                            <div className="header-cart-notification">
                              <HeaderCart
                                handleHeaderCart={handleToggleHeaderCart}
                                setHeaderCart={setHeaderCart}
                              />
                            </div>
                          )}
                        </div>
                      )}
                      {/* {null} */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </header>
      {showAddonsSubModal && (
        <AddonsModal
          show={showAddonsSubModal}
          onHide={handelOnMenuSearchClick}
          productName={activeProductName}
          activeProduct={activeProduct}
        />
      )}
    </div>
  );
};

export default AuthHeader;
