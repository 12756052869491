import Loader from "components/shared/Loader/Loader";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import WarningIcon from "../../../assets/images/cancel-order-icon.svg";
import ClearIcon from "@mui/icons-material/Clear";
import "./orderDetail.css"
import AlertMessage from "components/AlertMessage/AlertMessage";

const CancelOrderModal = ({
  show,
  onHide,
  handelCancelOrder,
  cancelOrderLoader,
  errorMessage,
  open,
  handleClose
}) => {
  return (
    <div>
      <Modal
        show={show}
        onHide={() => false}
        className="delivery-address cancel_order_modal"
        centered
      >
        <Modal.Body className="position-relative">
          <div>
            <div className="d-flex justify-content-end align-item-end cancel-modal-cross">
              <ClearIcon
                onClick={() => onHide()}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="success-check d-flex justify-content-center align-item-center cancel-order-img mt-5">
              <img
                src={WarningIcon}
                alt="no_img"
                style={{
                  marginBottom: "20px",
                }}
              />
            </div>
            {
              errorMessage &&
              <AlertMessage
                severity="error"
                icon={false}
                message={errorMessage?.response?.data?.error[0]}
                isOpen={open}
                onClose={handleClose}
                className={'mb-3'}
                autoCloseDuration={3000}
              />
            }
            <div>
              <p className="text-center fw-bold mb-1 fs-4">Cancel Order ?</p>
              <p className="text-center fw-bold mb-1 fs-6">
                Are you sure you want to cancel the order?
              </p>
              <p className="text-center  mb-4 fs-6">
                The order will be cancelled and a refund will be transferred at
                your <br />
                respective payment method.
              </p>
              <div className="w-100 text-center cancel-order-status">
                {/* <Button
                  className="success-place-order-btn"
                  style={{
                    marginRight: "15px",
                    background: "#DC3545",
                    color: "#fff",
                  }}
                  onClick={() => {
                    onHide();
                  }}
                >
                  Close
                </Button> */}
                <Button
                  className="success-place-order-btn common-btn"
                  onClick={() => handelCancelOrder()}
                  style={cancelOrderLoader ? { cursor: "not-allowed" } : {}}
                  disabled={cancelOrderLoader}
                >
                  <span className="d-flex align-item-center justify-content-center">
                    Cancel Order{" "}
                    {cancelOrderLoader && (
                      <Loader
                        Style={{
                          // height: "25px",
                          // width: "25px",
                          marginLeft: "20px",
                        }}
                        classes="widthtwintyFive"

                      />
                    )}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CancelOrderModal;
