const initialState = {
  cuisines: [],
  restaurants: [],
  serviceType: 1,
  asapAdvance: 1,
  deliverOrPickup: "delivery",
  deliverAndPickupNowHit: "",
  asapDate: new Date(),
  cityTimeSlotList: [],
  timeSlot: {},
  scheduleAsap: false,
  selectedRestaurantData: {},
  searchCusinesArr: [],
  asapAvailability: false,
};

const ResturantReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CUISINES_DATA":
      return {
        ...state,
        cuisines: action.payload,
      };
    case "SET_RESTAURANT_LIST":
      return {
        ...state,
        restaurants: action.payload,
      };
    case "GET_SERVICE_TYPE":
      return {
        ...state,
        serviceType: action.payload,
      };
    case "GET_ASAP_ADVANCE_TYPE":
      return {
        ...state,
        asapAdvance: action.payload,
      };
    case "GET_DELIVER_PICKUP_TAB":
      return {
        ...state,
        deliverOrPickup: action.payload,
      };
    case "GET_DELIVERY_PICKUP_NOW_HIT":
      return {
        ...state,
        deliverAndPickupNowHit: action.payload,
      };
    case "ASAP_DATE":
      return {
        ...state,
        asapDate: action.payload,
      };
    case "SET_CITY_TIME_SLOT":
      return {
        ...state,
        cityTimeSlotList: action.payload,
      };
    case "GET_TIME":
      return {
        ...state,
        timeSlot: action.payload,
      };
    case "SCHEDULE_ASAP":
      return {
        ...state,
        scheduleAsap: action.payload,
      };
    case "GET_SELECTED_RESTAURANT_DATA":
      return {
        ...state,
        selectedRestaurantData: action.payload,
      };
    case "GET_SELECT_CUISINES_SEARCH":
      return {
        ...state,
        searchCusinesArr: action.payload,
      };
    case "SET_ASAP_AVAILABILITY":
      return {
        ...state,
        asapAvailability: action.payload,
      };

    default:
      return state;
  }
};

export default ResturantReducer;
