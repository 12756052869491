import React from 'react'
import tooltipIcon from 'assets/images/tooltip-icon.svg'

const FeeAndSalesTooltip = ({ taxes }) => {
  return (
    <div>
      <div className='dev-tooltip'>
        <img src={tooltipIcon} alt='No_Image' />
        <div className='dev-tooltiptext'>
          {/* <figure>
            <img src={infoIcon} alt="No_Image" className="tooltip-image" />
          </figure> */}
          <h4 className='fs-6 fw-bold mb-3'>Fees & Sales Tax</h4>
          <ul>
            {Object.keys(taxes).map((key, ind) => {
              return (
                <li key={ind}>
                  <span className=''>{key}</span>
                  <span>
                    {/* {parseFloat(taxes[key].replace("$+", "")).toFixed(2)} */}
                    {taxes[key].replace('+', '')}
                  </span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default FeeAndSalesTooltip
