import { axiosInstance } from "services/axiosInstance";

function apiGet(url, dataParams = {}) {
  return axiosInstance.get(url, { params: dataParams });
}

function apiPost(url, body, dataParams = {}) {
  return axiosInstance.post(url, body, { params: dataParams });
}

function apiPut(url, body, dataParams = {}) {
  return axiosInstance.put(url, body, { params: dataParams });
}

function apiDelete(url) {
  return axiosInstance.delete(url);
}

export { apiGet, apiPost, apiPut, apiDelete };
