import signInIcon from "assets/images/signin-icon.svg";
import giftCardIcon from "assets/images/giftCard.svg";
import lisyoutrestutantIcon from "assets/images/lisyoutrestutant-icon.svg";
import becomeDriverIcon from "assets/images/become-driver-icon.svg";
import contactIcon from "assets/images/contactlight.svg";
import chatIcon from "assets/images/chat-square.svg";
import signUpIcon from "assets/images/signup-icon-mobile.svg";
import addressIcon from "assets/images/address-icon.svg";

// icons //
import purchageGiftIcon from "assets/images/pgc_img.svg";
import RewardIcon from "assets/images/wallet-icon.svg";
import notificationIcon from "assets/images/notification_side.svg";
import paymentIcon from "assets/images/payment_methods.svg";
import logoutIcon from "assets/images/logout.svg";
import PolicyIcon from "assets/images/PrivatePolicy.png";
import TermsIcon from "assets/images/TermsOfUse.png";
const Token = localStorage.getItem("access_token");

export const formatter = (response) => {
  const address = response.results[0].formatted_address;
  let city, state, country, postal_code, street_number, route;
  for (let i = 0; i < response.results[0].address_components.length; i++) {
    for (
      let j = 0;
      j < response.results[0].address_components[i].types.length;
      j++
    ) {
      switch (response.results[0].address_components[i].types[j]) {
        case "street_number":
          street_number = response.results[0].address_components[i].long_name;
          break;
        case "route":
          route = response.results[0].address_components[i].short_name;
          break;
        case "locality":
          city = response.results[0].address_components[i].long_name;
          break;
        case "administrative_area_level_1":
          state = response.results[0].address_components[i].long_name;
          break;
        case "country":
          country = response.results[0].address_components[i].long_name;
          break;
        case "postal_code":
          postal_code = response.results[0].address_components[i].long_name;
          break;
      }
    }
  }

  if (postal_code) {
    if (postal_code) {
      if (postal_code.includes("-")) {
        const parts = postal_code.split("-");
        postal_code = parts[0];
      }
    }
  }
  console.log("postal_code", postal_code);
  return {
    address,
    city,
    state,
    country,
    postal_code,
    street_number,
    route,
  };
};

export const restaurantSignupJson = [
  {
    id: 1,
    content:
      "Food Dudes is easy to use and built to be 100% user friendly for both customers and restaurants.",
  },
  {
    id: 2,
    content:
      "The process begins with a hungry customer. They browse your menu and place their order online or by phone.",
  },
  {
    id: 3,
    content:
      "The order is received by Food Dudes and then sent directly to your restaurant. A driver is notified that the order has been placed and arrives for pick-up.",
  },
  {
    id: 4,
    content:
      "The customer is notified that their food is on the way. Our driver delivers the order promptly and thanks the customer.",
  },
  {
    id: 5,
    content: "In the end, both the customer and restaurant are satisfied.",
  },
];

export const sidebarValues = [
  {
    id: 1,
    navigate: "/sign-in",
    title: "Sign In",
    auth: false,
    icon: signInIcon,
  },

  {
    id: 2,
    icon: signUpIcon,
    navigate: "/sign-up",
    title: "Sign Up",
    auth: false,
  },
  // {
  //   id: 3,
  //   title: "My Account",
  //   auth: false,
  //   icon: accountIcon,
  // },
  {
    id: 4,
    navigate: "/order",
    title: "Orders",
    auth: false,
    icon: signInIcon,
  },

  {
    id: 5,
    title: "Addresses",
    auth: false,
    icon: addressIcon,
  },

  {
    id: 6,
    title: "Purchase Gift Card",
    auth: false,
    icon: purchageGiftIcon,
    navigate: "/buy-gift-card",
  },
  {
    id: 7,
    title: "Rewards",
    auth: false,
    icon: RewardIcon,
    navigate: "/rewards",
  },
  {
    id: 8,
    title: "Notification",
    auth: false,
    icon: notificationIcon,
    navigate: "/notification",
  },
  {
    id: 9,
    title: "Gift Card",
    auth: false,
    icon: giftCardIcon,
    // navigate: Token ? "/giftcard" : "/buy-gift-card",
    navigate: "/giftcard",
  },
  {
    id: 10,
    title: "Payment Method",
    auth: false,
    icon: paymentIcon,
    navigate: "/payment-method",
  },
  {
    id: 11,
    icon: lisyoutrestutantIcon,
    // navigate: "/restaurant-signup/5",
    navigate: "/restaurant-signup",
    title: "List a Restaurant",
    auth: false,
  },

  {
    id: 12,
    icon: becomeDriverIcon,
    // navigate: "/driver-signup/4",
    navigate: "/driver-signup",
    title: "Become a Driver",
    auth: false,
  },
  {
    id: 13,
    icon: contactIcon,
    navigate: "/contact-us",
    title: "Contact Us",
    auth: false,
  },
  {
    id: 14,
    icon: chatIcon,
    // navigate: "/chat-with-us",
    navigate: "/chat",
    title: "Chat with Us",
    auth: false,
  },
  // {
  //   id: 15,
  //   icon: carrIcon,
  //   navigate: "/career",
  //   title: "Career",
  //   auth: false,
  // },
  {
    id: 16,
    icon: TermsIcon,
    title: "Terms of Use",
    navigate: "/terms-of-use",
    auth: false,
  },
  {
    id: 17,
    icon: PolicyIcon,
    title: "Privacy Policy",
    navigate: "/privacy-policy",
    auth: false,
  },
  {
    id: 18,
    icon: logoutIcon,
    title: "Logout",
    auth: false,
  },
];

export const badCatKeywordsForSeo = [
  {
    id: 1,
    title: "Food Dudes Delivery Service | Order Local Restaurant Food Online",
    description:
      "Place an order with Food Dudes Delivery - your local food delivery service. Order Pizza, Mexican, Chinese, Fast Food, & More!",
    keyword:
      "food delivery service (2,900), food delivery (22,200), order food (1,900), order food online (1,600), meal delivery (1,900)",
    page: "home",
  },
  {
    id: 2,
    title: "Sign into Your Food Dudes Delivery Account | Food Delivery Service",
    description:
      "Sign into your Food Dudes Delivery account to place another order. Purchase an old favorite or try something new with Food Dudes Delivery.",
    keyword:
      "sign into food dudes (0), my food dudes account (0), sign into my food dudes account (0), food dudes account (0)",
    page: "sign-in",
  },
  {
    id: 3,
    title:
      "Sign up for a Food Dudes Delivery Account to Place Your First Order",
    description:
      "Sign up for a Food Dudes Delivery account to start placing orders. Get food delivery from your favorite restaurant or try something new.",
    keyword:
      "sign up for food delivery (10), food delivery sign up (10), sign up for food dudes (0), create a food dudes account (0)",
    page: "sign-up",
  },
  {
    id: 4,
    title: "Online Food Delivery Gift Cards | Food Dudes Delivery Services",
    description:
      "Purchase a gift card or gift certificate from Food Dudes Delivery - a top food delivery service in the midwest.",
    keyword:
      "food delivery gift card (70), food dudes gift card (10), meal delivery gift card (20), food delivery services (10), food delivery gift certificate (10)",
    page: "gift-card",
  },
  {
    id: 5,
    title:
      "List Your Restaurant on Food Dudes Delivery | Food Delivery Service",
    description:
      "Are you looking expand your business to include food delivery? List your company on our website and have our drivers deliver your food.",
    keyword:
      "third party food delivery (10), food delivery for restaurants (170), online food delivery (50), best food delivery service (720), delivery services for restaurants (90)",
    page: "restaurant-signup",
  },
  {
    id: 6,
    title: "Apply to Become a Driver | Food Dudes Delivery Jobs in the Midwest",
    description:
      "Are you looking for a flexible job that allows you to create your own schedule? Apply to Food Dudes Delivery to get started today!",
    keyword:
      "food delivery jobs (210), driving jobs (1,300), entry level jobs (880), delivery driving jobs (1,300), flexible schedule job (210)",
    page: "driver-signup",
  },
  {
    id: 7,
    title: "Contact Food Dudes Delivery | Online Local Food Delivery Service",
    description:
      "Do you have questions about our services? Contact Food Dudes Delivery Service to talk to a representative.",
    keyword:
      "food dudes phone number (20), food dudes number (10), contact food dudes (0), email food dudes (0), message food dudes (0)",
    page: "contact-us",
  },
  {
    id: 8,
    title:
      "Food Dudes Delivery Careers | Food Dudes Delivery - Local Delivery Service",
    description:
      "Whether you are looking for your first job or a career change, Food Dudes Delivery has a position for you. Apply for a position today!",
    keyword:
      "food dudes jobs (10), restaurant delivery jobs (10), food delivery driver (70), become a food delivery driver (10), food delivery work (10)",
    page: "career",
  },
];

export const dummyAddonsData = [
  {
    options_id: 157867,
    products_options_name: "Variations",
    attributes_min_select: 1,
    attributes_max_select: 1,
    products_options_type: 2,
    min_aggregate_quantity: 0,
    max_aggregate_quantity: 0,
    increment_choice_quantity: 1,
    support_choice_quantities: false,
    free_modifier_quantity: 0,
    optionValues: [
      {
        options_values_id: 1354029,
        products_options_values_name: "Small Platter",
        options_values_price: "5.3800",
        attributes_required: 0,
        attributes_default: 1,
        products_attributes_id: 2559631,
        attributes_price_base_included: 1,
        quantity: 1,
        options_value_text: "",
        min_choice_quantity: 1,
        max_choice_quantity: 0,
        second_level_options: [],
      },
      {
        options_values_id: 1354030,
        products_options_values_name: "Large Platter",
        options_values_price: "8.6000",
        attributes_required: 0,
        attributes_default: 0,
        products_attributes_id: 2559632,
        attributes_price_base_included: 0,
        quantity: 1,
        options_value_text: "",
        min_choice_quantity: 1,
        max_choice_quantity: 0,
        second_level_options: [],
      },
    ],
  },
  {
    options_id: 157868,
    products_options_name: "Platter Options",
    attributes_min_select: 1,
    attributes_max_select: 1,
    products_options_type: 2,
    min_aggregate_quantity: 0,
    max_aggregate_quantity: 0,
    increment_choice_quantity: 1,
    support_choice_quantities: false,
    free_modifier_quantity: 0,
    optionValues: [
      {
        options_values_id: 1354031,
        products_options_values_name: "Chips and Salsa",
        options_values_price: "0.0000",
        attributes_required: 0,
        attributes_default: 0,
        products_attributes_id: 2559633,
        attributes_price_base_included: 0,
        quantity: 1,
        options_value_text: "",
        min_choice_quantity: 1,
        max_choice_quantity: 0,
        second_level_options: [
          {
            options_id: 157879,
            products_options_name: "Add Salsa",
            attributes_min_select: 0,
            attributes_max_select: 3,
            products_options_type: 3,
            min_aggregate_quantity: 0,
            max_aggregate_quantity: 0,
            increment_choice_quantity: 1,
            support_choice_quantities: true,
            free_modifier_quantity: 1,
            optionValues: [
              {
                options_values_id: 1354032,
                products_options_values_name: "Mild",
                options_values_price: "1.6900",
                attributes_required: 0,
                attributes_default: 0,
                products_attributes_id: 2559661,
                attributes_price_base_included: 0,
                quantity: 1,
                options_value_text: "",
                min_choice_quantity: 1,
                max_choice_quantity: 0,
              },
              {
                options_values_id: 1354033,
                products_options_values_name: "Hot",
                options_values_price: "2.6900",
                attributes_required: 0,
                attributes_default: 0,
                products_attributes_id: 2559662,
                attributes_price_base_included: 0,
                quantity: 1,
                options_value_text: "",
                min_choice_quantity: 1,
                max_choice_quantity: 0,
              },
              {
                options_values_id: 1354034,
                products_options_values_name: "Extra Hot (Caution)",
                options_values_price: "3.6900",
                attributes_required: 0,
                attributes_default: 0,
                products_attributes_id: 2559663,
                attributes_price_base_included: 0,
                quantity: 1,
                options_value_text: "",
                min_choice_quantity: 1,
                max_choice_quantity: 0,
              },
            ],
          },
        ],
      },
      {
        options_values_id: 1354035,
        products_options_values_name: "Bread",
        options_values_price: "0.0000",
        attributes_required: 0,
        attributes_default: 0,
        products_attributes_id: 2559637,
        attributes_price_base_included: 0,
        quantity: 1,
        options_value_text: "",
        min_choice_quantity: 1,
        max_choice_quantity: 0,
        second_level_options: [
          {
            options_id: 157880,
            products_options_name: "Spread Options",
            attributes_min_select: 0,
            attributes_max_select: 2,
            products_options_type: 3,
            min_aggregate_quantity: 0,
            max_aggregate_quantity: 0,
            increment_choice_quantity: 1,
            support_choice_quantities: true,
            free_modifier_quantity: 1,
            optionValues: [
              {
                options_values_id: 1354036,
                products_options_values_name: "Berry Jam",
                options_values_price: "2.6900",
                attributes_required: 0,
                attributes_default: 0,
                products_attributes_id: 2559664,
                attributes_price_base_included: 0,
                quantity: 1,
                options_value_text: "",
                min_choice_quantity: 1,
                max_choice_quantity: 0,
              },
              {
                options_values_id: 1354037,
                products_options_values_name: "Butter",
                options_values_price: "1.0800",
                attributes_required: 0,
                attributes_default: 0,
                products_attributes_id: 2559665,
                attributes_price_base_included: 0,
                quantity: 1,
                options_value_text: "",
                min_choice_quantity: 1,
                max_choice_quantity: 0,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    options_id: 40,
    products_options_name: "Special Instructions",
    attributes_min_select: 0,
    attributes_max_select: 0,
    products_options_type: 1,
    min_aggregate_quantity: 0,
    max_aggregate_quantity: 0,
    increment_choice_quantity: 1,
    support_choice_quantities: true,
    free_modifier_quantity: 0,
    optionValues: [
      {
        options_values_id: 0,
        products_options_values_name: "TEXT",
        options_values_price: "0.0000",
        attributes_required: 0,
        attributes_default: 0,
        products_attributes_id: 2559640,
        attributes_price_base_included: 0,
        quantity: 1,
        options_value_text: "",
        min_choice_quantity: 1,
        max_choice_quantity: 0,
        second_level_options: [],
      },
    ],
  },
];
