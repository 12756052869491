const initialState = {
  showDriverTrip: false,
  showCheckoutDateTime: false,
  showSalesTaxModal: false,
  showDeliveryAddress: false,
  dropOption: "Hand to me",
  showAddonsModalCheckout: false,
  orderCheckoutAddress: {},
  verifyAddressDateTime: "",
  giftCardList: [],
  walletBallance: {},
  couponGiftCard: {},
  showAddFundModal: false,
  clientToken: "",
  paymentSettingData: {},
  paymentOption: {},
  driverTipType: "percent",
  driverTipValue: "15",
  driverTipIsCustom: false,
  reCallCartApiForOrderCheckout: false,
  hitPlaceOrderKey: "",
  showSuccessOrderUi: false,
  stopFirstRender: true,
  priceOrderCheckout: {},
  walletGiftCardSeries: {},
  placeOrderData: {},
  giftCard: [],
  eGiftCardStatus: false,
  orderCheckoutError: "",
};

const CheckoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DRIVER_TIP":
      return {
        ...state,
        showDriverTrip: action.payload,
      };
    case "CHECKOUT_DATE_TIME":
      return {
        ...state,
        showCheckoutDateTime: action.payload,
      };
    case "SALES_TAX_MODAL":
      return {
        ...state,
        showSalesTaxModal: action.payload,
      };
    case "DELIVERY_ADDRESS_MODAL":
      return {
        ...state,
        showSalesTaxModal: action.payload,
      };
    case "DROP_OPTION":
      return {
        ...state,
        dropOption: action.payload,
      };
    case "SHOE_ADDONS_MODAL_ON_ORDER_CHECKOUT":
      return {
        ...state,
        showAddonsModalCheckout: action.payload,
      };
    case "ORDER_CHECKOUT_DEFAULT_ADDRESS":
      return {
        ...state,
        orderCheckoutAddress: action.payload,
      };
    case "SET_VERIFY_ADDRESS_DATE_TIME":
      return {
        ...state,
        verifyAddressDateTime: action.payload,
      };
    case "SET_GIFT_CARD_LIST":
      return {
        ...state,
        giftCardList: action.payload,
      };
    case "SET_WALLET_BALANCE":
      return {
        ...state,
        walletBallance: action.payload,
      };
    case "SET_VALIDATE_COUPON_GIFT_CARD":
      return {
        ...state,
        couponGiftCard: action.payload,
      };

    case "SET_EGIFT_CARD_CODE_STATUS":
      return {
        ...state,
        eGiftCardStatus: action.payload,
      };

    case "SET_VALIDATE_GIFT_CARD":
      return {
        ...state,
        giftCard: action.payload,
      };

    case "SHOW_ADD_FUND_MODAL":
      return {
        ...state,
        showAddFundModal: action.payload,
      };
    case "SET_CLIENT_TOKEN":
      return {
        ...state,
        clientToken: action.payload,
      };
    case "SET_PAYMENT_SETTING":
      return {
        ...state,
        paymentSettingData: action.payload,
      };
    case "SET_PAYMENT_OPTION":
      return {
        ...state,
        paymentOption: action.payload,
      };
    case "SET_DRIVER_TIP_TYPE":
      return {
        ...state,
        driverTipType: action.payload,
      };

    case "SET_DRIVER_TIP_VALUE":
      return {
        ...state,
        driverTipValue: action.payload,
      };

    case "SET_DRIVER_TIP_IS_CUSTOM":
      return {
        ...state,
        driverTipIsCustom: action.payload,
      };

    case "GET_CALL_CART_API_IN_ORDER_CHECKOUT":
      return {
        ...state,
        reCallCartApiForOrderCheckout: action.payload,
      };
    case "GET_HIT_PLACE_ORDER_KEY":
      return {
        ...state,
        hitPlaceOrderKey: action.payload,
      };
    case "SHOW_PLACE_ORDER_SUCCESS_UI":
      return {
        ...state,
        showSuccessOrderUi: action.payload,
      };
    case "STOP_FIRST_RENDER":
      return {
        ...state,
        stopFirstRender: action.payload,
      };
    case "SET_ORDER_CHECKOUT_PRICE_COUNT":
      return {
        ...state,
        priceOrderCheckout: action.payload,
      };
    case "SET_ORDER_CHECKOUT_ERROR":
      return {
        ...state,
        orderCheckoutError: action.payload,
      };
    case "SET_WALLET_GIFT_CARD_SERIES":
      return {
        ...state,
        walletGiftCardSeries: action.payload,
      };
    case "SET_PLACE_ORDER":
      return {
        ...state,
        placeOrderData: action.payload,
      };

    default:
      return state;
  }
};

export default CheckoutReducer;
