const initialState = {
  states: { data: [], loading: false },
  cities: { data: [] },
  city: {},
  sidebarVisible: false,
  restaurantsOnSearch: [],
  showSearchScreen: false,
  headerNotification: [],
  showHeaderPopUp: false,
  activeAccount: "",
  showRestaurantCart: false,
  autoApplyCoupon: "",
  showTickerMessage: false,
  showTickerText: "",
  citySeoData: {},
  addressBookId: "",
  notificationExist: 0,
};

const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_STATE_INFO":
      return {
        ...state,
        states: { data: action?.payload?.states ?? [], loading: true },
        cities: { data: action?.payload?.cities ?? [] },
      };

    case "SET_STATE_INFO":
      return {
        ...state,
        states: { data: action.payload?.states, loading: false },
        cities: { data: action?.payload?.cities ?? [] },
      };
    case "SHOW_SIDE_BAR":
      return {
        ...state,
        sidebarVisible: action.payload,
      };
    case "SET_RESTAURANT_LIST_HEADER":
      return {
        ...state,
        restaurantsOnSearch: action.payload,
      };
    case "SET_CITY_DETAILS":
      return {
        ...state,
        city: action.payload,
      };
    case "SHOW_BOTTOM_NAVIGATION_SEARCH":
      return {
        ...state,
        showSearchScreen: action.payload,
      };
    case "SET_HEADER_NOTIFICATION":
      return {
        ...state,
        headerNotification: action.payload,
      };
    case "GET_NOTIFICATION_POPUP":
      return {
        ...state,
        showHeaderPopUp: action.payload,
      };
    case "GET_ACTIVE_ACCOUNT":
      return {
        ...state,
        activeAccount: action.payload,
      };
    case "GET_SHOW_RESTAURANT_CART":
      return {
        ...state,
        showRestaurantCart: action.payload,
      };
    case "GET_AUTO_APPLIED_COUPON":
      return {
        ...state,
        autoApplyCoupon: action.payload,
      };
    case "SHOW_TICKER":
      return {
        ...state,
        showTickerMessage: action.payload,
      };
    case "SHOW_TICKER_TEXT":
      return {
        ...state,
        showTickerText: action.payload,
      };
    case "GET_CITY_SEO_DATA":
      return {
        ...state,
        citySeoData: action.payload,
      };
    case "GET_ADDRESS_BOOK_ID":
      return {
        ...state,
        addressBookId: action.payload,
      };
    case "SET_NOTIFICATION_EXIST":
      return {
        ...state,
        notificationExist: action.payload,
      };

    default:
      return state;
  }
};

export default HomeReducer;
