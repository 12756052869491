export const alphaInput = event => {
  if (event) {
    var char = String.fromCharCode(event.which)
    if (!/^[a-zA-Z ]+$/.test(char)) {
      event.preventDefault()
    }
  }
}

export const floatInput = event => {
  if (event) {
    var char = String.fromCharCode(event.which)
    if (!/^\d*(\.\d{0,2})?$/.test(char)) {
      event.preventDefault()
    }
  }
}

export const integerInput = event => {
  if (event) {
    var char = String.fromCharCode(event.which)
    if (!/[0-9]/.test(char)) {
      event.preventDefault()
    }
  }
}
export const integerNotZeroInput = event => {
  if (event) {
    var char = String.fromCharCode(event.which)
    if (!/[1-9]/.test(char)) {
      event.preventDefault()
    }
  }
}
