import React from "react";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_TICKER } from "store/Action/HomeAction";
import { textTruncateMore } from "helpers/textTruncate/TextTruncate";

const FloatingActionContainer = () => {
  const dispatch = useDispatch();
  //ALL SELECTORS
  const { SHOW_TICKER_TEXT_STATE } = useSelector((store) => ({
    SHOW_TICKER_TEXT_STATE: store?.HomeReducer?.showTickerText ?? "",
  }));

  return (
    <div>
      <div className="floating-header">
        <NewReleasesIcon className="d-sm-block d-none" />
        <p>{textTruncateMore(SHOW_TICKER_TEXT_STATE, 52) ?? ""}</p>

        <CloseIcon
          className="position-absolute end-0 cursor-pointer"
          onClick={() => dispatch(SHOW_TICKER(false))}
        />
      </div>
    </div>
  );
};

export default FloatingActionContainer;
