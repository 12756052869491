import { all } from "redux-saga/effects";
import { HomeWatcher } from "../Saga/HomeWatcher";
import { AuthWatcher } from "./AuthWatcher";
import { CheckoutWatcher } from "./CheckoutWatcher";
import { RasturantMenuWatcher } from "./RasturantMenuWatcher";
import { RasturantWatcher } from "./RasturantWatcher";
import { AddressWatcher } from "./Sidebar/AddressWatcher";
import { OrderWatcher } from "./Sidebar/OrderWatcher";
import { SidebarWatcher } from "./Sidebar/SidebarWatcher";
import { RewardsWatcher } from "./Sidebar/RewardsWatcher";
import { GiftCardWatcher } from "./Sidebar/GiftCardWatcher";
import { PurchaseGiftCardWatcher } from "./Sidebar/PurchaseGiftCardWatcher";

export function* rootSaga() {
  yield all([
    HomeWatcher(),
    AuthWatcher(),
    SidebarWatcher(),
    RasturantWatcher(),
    AddressWatcher(),
    RasturantMenuWatcher(),
    CheckoutWatcher(),
    OrderWatcher(),
    RewardsWatcher(),
    GiftCardWatcher(),
    PurchaseGiftCardWatcher(),
  ]);
}
