import React, { useState, useEffect } from 'react'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
  Polyline,
  Polygon
} from '@react-google-maps/api'
import { useSelector, useDispatch } from 'react-redux'
import markerImg from 'assets/images/marker.svg'
import restaurantImg from 'assets/images/icon-resturant.svg'
import addressImg from 'assets/images/icon-address.svg'
import { GOOGLE_MAP_API_KEY } from 'services/path'

const defaultFunction = () => null

const OrderDetailCurveMap = props => {
  // Load the Google maps scripts
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    version: '3.47'
  })

  //ALL SELECTORS
  const { SERVICE_TYPE } = useSelector(store => ({
    SERVICE_TYPE: store.ResturantReducer.serviceType
  }))

  const {
    cities = [],
    activeMarker = '',
    handleOnLoad = defaultFunction,
    setActiveMarker = defaultFunction,
    handleActiveMarker = defaultFunction,
    dispatch = defaultFunction,
    // selectedAddress = defaultFunction,
    polylineOptions = {},
    height,
    ORDER_DETAILS
  } = props

  const onUnmountHandler = e => {}

  const [mapZoom, Zoom] = useState(props.mapZoom ? props.mapZoom : 10)
  // const store = useSelector((store) => store);
  useEffect(() => {
    if (props.mapZoom) {
      if (SERVICE_TYPE == 2) {
        Zoom(16)
      } else {
        Zoom(props.mapZoom)
      }
    }
  }, [SERVICE_TYPE])

  useEffect(() => {
    if (!SERVICE_TYPE == 1) {
      props.mapValue.setMap(null)
    }
  }, [SERVICE_TYPE])

  useEffect(() => {
    if (ORDER_DETAILS.mode !== 'Delivery') {
      props.isCheckOut &&
        ORDER_DETAILS.mode == 'Pickup' &&
        props.mapValue &&
        setTimeout(() => props.mapValue.setZoom(17), 500)
    }
  }, [props.mapValue, SERVICE_TYPE])

  const handleMapClick = (e) => {
    e.stop();
  };

  if (!props.isAddress) {
    return (
      <>
        {isLoaded && cities && cities?.length ? (
          <GoogleMap
            options={{
              draggable: false,
              disableDoubleClickZoom: true,
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              panControl: false,
              rotateControl: false,
              fullscreenControl: false
            }}
            defaultOptions={{
              fullscreenControl: false
            }}
            onLoad={handleOnLoad}
            //onClick={() => setActiveMarker(null)}
            onClick={(e) => handleMapClick(e)}
            mapContainerStyle={{ width: '100%', height: height || '300px' }}
            zoom={mapZoom}
          >
            {(SERVICE_TYPE == 2 || !props.isCheckOut) &&
              cities.map(({ id, name, position, img }) => (
                <Marker
                  key={id}
                  position={position}
                  onClick={() => handleActiveMarker(id)}
                  icon={img ? img : markerImg}
                  onMouseOver={() => handleActiveMarker(id)}
                >
                  {activeMarker === id ? (
                    <InfoWindow
                      position={position}
                      onCloseClick={() => setActiveMarker(null)}
                    >
                      <div
                        className='markar__tooltip'
                        style={{ cursor: 'pointer' }}
                      >
                        {name}
                      </div>
                    </InfoWindow>
                  ) : null}
                </Marker>
              ))}
            {props.mapProjection && props.polylineOptions && props.mapZoom ? (
             ( props?.cities[0]?.position && props?.cities[1]?.position) &&
              <CurveMarker
                pos1={props?.cities[0]?.position}
                pos2={props?.cities[1]?.position}
                mapProjection={props?.mapProjection}
                zoom={mapZoom}
                mapValue={props?.mapValue}
                isSingleIcon={SERVICE_TYPE == 1 ? false : true}
              />
            ) : null}
          </GoogleMap>
        ) : null}
      </>
    )
  }
}

export default OrderDetailCurveMap

var curvature = 0.3

const CurveMarker = ({
  pos1,
  pos2,
  mapProjection,
  zoom,
  mapValue,
  isSingleIcon
}) => {
  if (!mapProjection) return <div></div>
  var pos1 = new window.google.maps.LatLng(pos1?.lat, pos1?.lng)
  var pos2 = new window.google.maps.LatLng(pos2?.lat, pos2?.lng)

  var markerP1 = new window.google.maps.Marker({
    position: pos1,
    draggable: false,
    map: mapValue,
    icon: restaurantImg
  })
  var markerP2 = new window.google.maps.Marker({
    position: pos2,
    draggable: false,
    map: mapValue,
    icon: addressImg
  })

  if (!mapProjection) return <div></div>
  var pos1 = markerP1?.getPosition(), // latlng
    pos2 = markerP2?.getPosition()
  var p1 = mapProjection?.fromLatLngToPoint(pos1),
    p2 = mapProjection?.fromLatLngToPoint(pos2)

  // Calculating the arc.
  const e = new window.google.maps.Point(p2.x - p1.x, p2.y - p1.y), // endpoint
    m = new window.google.maps.Point(e.x / 2, e.y / 2), // midpoint
    o = new window.google.maps.Point(e.y, -e.x), // orthogonal
    c = new window.google.maps.Point(
      m.x + curvature * o.x,
      m.y + curvature * o.y
    ) //curve control point

  const pathDef = 'M 0,0' + 'q ' + c.x + ',' + c.y + ' ' + e.x + ',' + e.y
  const scale = 1 / Math.pow(2, -zoom)

  const symbol = {
    path: pathDef,
    scale: scale,
    strokeWeight: 2,
    strokeColor: '#000000',
    fillColor: 'red',
    repeat: '20px',
    strokeOpacity: 1,
    geodesic: true
  }

  var lineLength = window.google.maps.geometry.spherical.computeDistanceBetween(
    markerP1.getPosition(),
    markerP2.getPosition()
  )
  var lineHeading = window.google.maps.geometry.spherical.computeHeading(
    markerP1.getPosition(),
    markerP2.getPosition()
  )

  var markerA = new window.google.maps.Marker({
    position: window.google.maps.geometry.spherical.computeOffset(
      markerP1.getPosition(),
      lineLength / 2,
      lineHeading - 38
    ),
    map: mapValue,

    icon: {
      url: 'ss',
      size: new window.google.maps.Size(7, 7),
      anchor: new window.google.maps.Point(3.5, 3.5)
    }
  })
  var markerB = new window.google.maps.Marker({
    position: window.google.maps.geometry.spherical.computeOffset(
      markerP2.getPosition(),
      lineLength / 12,
      -lineHeading + 76
    ),
    icon: {
      url: 'ss',
      size: new window.google.maps.Size(7, 7),
      anchor: new window.google.maps.Point(3.5, 3.5)
    },
    map: mapValue
  })

  var curvedLine = new GmapsCubicBezier(
    markerP1.getPosition(),
    markerA.getPosition(),
    markerB.getPosition(),
    markerP2.getPosition(),
    0.01,
    mapValue,
    isSingleIcon
  )
  return curvedLine
  return <Marker position={pos1} clickable={false} icon={symbol} zIndex={1} />
}

var GmapsCubicBezier = function (
  latlong1,
  latlong2,
  latlong3,
  latlong4,
  resolution,
  map,
  isSingleIcon
) {
  var lat1 = latlong1.lat()
  var long1 = latlong1.lng()
  var lat2 = latlong2.lat()
  var long2 = latlong2.lng()
  var lat3 = latlong3.lat()
  var long3 = latlong3.lng()
  var lat4 = latlong4.lat()
  var long4 = latlong4.lng()

  var points = []

  for (let it = 0; it <= 1; it += resolution) {
    points.push(
      this.getBezier(
        {
          x: lat1,
          y: long1
        },
        {
          x: lat2,
          y: long2
        },
        {
          x: lat3,
          y: long3
        },
        {
          x: lat4,
          y: long4
        },
        it
      )
    )
  }
  var path = []
  for (var i = 0; i < points.length - 1; i++) {
    path.push(new window.google.maps.LatLng(points[i].x, points[i].y))
    path.push(
      new window.google.maps.LatLng(points[i + 1].x, points[i + 1].y, false)
    )
  }
  const lineSymbol = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    scale: 4
  }

  return (
    <Polyline
      path={path}
      options={{
        fillColor: 'lightblue',
        fillOpacity: 1,
        strokeOpacity: 0,
        strokeWeight: 0,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1,
        icons: [
          {
            icon: lineSymbol,
            offset: '-20',
            repeat: '20px'
          }
        ]
      }}
      strokeOpacity={0}
    />
  )
}

GmapsCubicBezier.prototype = {
  B1: function (t) {
    return t * t * t
  },
  B2: function (t) {
    return 3 * t * t * (1 - t)
  },
  B3: function (t) {
    return 3 * t * (1 - t) * (1 - t)
  },
  B4: function (t) {
    return (1 - t) * (1 - t) * (1 - t)
  },
  getBezier: function (C1, C2, C3, C4, percent) {
    var pos = {}
    pos.x =
      C1.x * this.B1(percent) +
      C2.x * this.B2(percent) +
      C3.x * this.B3(percent) +
      C4.x * this.B4(percent)
    pos.y =
      C1.y * this.B1(percent) +
      C2.y * this.B2(percent) +
      C3.y * this.B3(percent) +
      C4.y * this.B4(percent)
    return pos
  }
}
