import React from "react";
import Lottie from "react-lottie";
import Loader from "assets/lottiAnimation/Loader.json";
import NoData from "assets/lottiAnimation/NoData.json";
import SerchEmpty from "assets/lottiAnimation/search_empty.json";
// import NoData from 'assets/lottiAnimation/EmptyCart.json'
import { Link } from "react-router-dom";

export default function LottiLoader({
  loader,
  height,
  width,
  message,
  previousMessage,
  linkTo,
  type,
}) {
  

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader ? Loader : type ? SerchEmpty : NoData,
    //animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <span className={loader ? "page-loading-new" : "w-100"}>
      <Lottie
        options={defaultOptions}
        height={height ?? 120}
        width={width ?? 120}
      />
      <p
        style={{
          color: "#5c6873",
          fontWeight: "600",
          textAlign: "center",
          marginTop: "20px",
          fontSize: "20px",
        }}
      >
        {message ?? ""}
      </p>

      {linkTo && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#F06F00",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          <Link
            to={linkTo}
            style={{ textDecoration: "none", color: "#F06F00" }}
          >
            {previousMessage ?? ""}
          </Link>
        </div>
      )}
    </span>
  );
}
