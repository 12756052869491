const GET_ALL_ADDRESS = (data, callback) => {
  return {
    type: "GET_ALL_ADDRESS",
    payload: data,
    callback,
  };
};

const SET_ALL_ADDRESS = (data) => {
  return {
    type: "SET_ALL_ADDRESS",
    payload: data,
  };
};
const GET_ADDRESS_MODAL_TYPE = (data) => {
  return {
    type: "GET_ADDRESS_MODAL_TYPE",
    payload: data,
  };
};
const GET_ZONE = (data, callback) => {
  return {
    type: "GET_ZONE",
    payload: data,
    callback,
  };
};
const SET_ZONE = (data, callback) => {
  return {
    type: "SET_ZONE",
    payload: data,
  };
};
const SELECTED_ADDRESS = (data) => {
  return {
    type: "SELECTED_ADDRESS",
    payload: data,
  };
};
const GET_PRIMARY_ADDRESS = (data, callback) => {
  return {
    type: "GET_PRIMARY_ADDRESS",
    payload: data,
    callback,
  };
};
const GET_ADD_EDIT_ADDRESS = (data, callback) => {
  return {
    type: "GET_ADD_EDIT_ADDRESS",
    payload: data,
    callback,
  };
};
const GET_DELETE_ADDRESS = (data, callback) => {
  return {
    type: "GET_DELETE_ADDRESS",
    payload: data,
    callback,
  };
};

const GET_ALL_ADDRESS_ORDER_CHECKOUT = (data, callback) => {
  return {
    type: "GET_ALL_ADDRESS_ORDER_CHECKOUT",
    payload: data,
    callback,
  };
};
const GET_NEAREST_ADDRESS = (data, callback) => {
  return {
    type: "GET_NEAREST_ADDRESS",
    payload: data,
    callback,
  };
};

export {
  GET_ALL_ADDRESS,
  SET_ALL_ADDRESS,
  GET_ADDRESS_MODAL_TYPE,
  GET_ZONE,
  SET_ZONE,
  SELECTED_ADDRESS,
  GET_PRIMARY_ADDRESS,
  GET_ADD_EDIT_ADDRESS,
  GET_DELETE_ADDRESS,
  GET_ALL_ADDRESS_ORDER_CHECKOUT,
  GET_NEAREST_ADDRESS,
};
