import React from 'react'
import { Button } from 'react-bootstrap'
import CloseIcon from '@mui/icons-material/Close'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useNavigate } from 'react-router'

const HeaderNotification = ({ handelReadNotification }) => {
  const navigate = useNavigate()
  const { TOKEN, NOTIFICATION_LIST } = useSelector(store => ({
    TOKEN: store.AuthReducer.token,
    NOTIFICATION_LIST: store?.HomeReducer?.headerNotification?.listing
  }))
  return (
    <div>
      {' '}
      <div className='header-notificatin-wrapper'>
        <div className='d-flex align-items-center justify-content-between pb-1'>
          <h4>Notifications</h4>
          <Button className='bg-transparent border-0 text-dark'>
            <CloseIcon />
          </Button>
        </div>
        <ul className='notification_lists mb-0'>
          {NOTIFICATION_LIST &&
            NOTIFICATION_LIST?.length !== 0 &&
            NOTIFICATION_LIST?.map((item, index) => {
              return (
                <li className='read-notify'>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item?.message
                    }}
                    style={item?.is_read ? { color: '#86888A' } : {}}
                    className='notification-para'
                  ></p>
                  <span className='notification_lists_timestamp'>
                    {' '}
                    {moment(item?.created_at).format('ddd MM/DD/YYYY h:mm A')}
                  </span>
                </li>
              )
            })}
        </ul>

        <Button
          className='common-btn px-4'
          onClick={() => {
            handelReadNotification()
            navigate('/notification')
          }}
        >
          View all
        </Button>
      </div>
    </div>
  )
}

export default HeaderNotification
