import { takeLatest, call, put } from "redux-saga/effects";
import { pathObj } from "../../../services/apiPath";
import { apiGet, apiPost } from "services/httpServices";
import { successToast } from "utils/toastMessage";

function* getAllAddress(action) {
  let { callback } = action;
  callback && callback(true, false);
  try {
    const response = yield call(apiGet, pathObj.ALL_ADDRESS);
    if (response.status == 200) {
      yield put({
        type: "SET_ALL_ADDRESS",
        payload: response?.data?.data,
      });
      callback && callback(false, false, response?.data?.data);
    } else {
      callback && callback({}, false, true, []);
      yield put({
        type: "SET_ALL_ADDRESS",
        payload: [],
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true, []);
    yield put({
      type: "SET_ALL_ADDRESS",
      payload: [],
    });
  }
}

function* handelSetPrimaryAddress(action) {
  let { callback } = action;
  callback && callback(true, false);
  try {
    const response = yield call(
      apiGet,
      pathObj.SET_PRIMARY_ADDRESS + action.payload
    );
    if (response.status == 200) {
      // successToast(response.data.message);
      callback && callback(false, false);
    } else {
      callback && callback({}, false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);

    console.log(error);
  }
}
function* getAllZone(action) {
  let { callback } = action;
  callback && callback(true, false);
  try {
    const response = yield call(apiGet, pathObj.ALL_ZONE);
    if (response.status == 200) {
      yield put({
        type: "SET_ZONE",
        payload: response?.data?.data,
      });
      callback && callback(false, false);
    } else {
      callback && callback({}, false, true);
      yield put({
        type: "SET_ZONE",
        payload: [],
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    yield put({
      type: "SET_ZONE",
      payload: [],
    });
    console.log(error);
  }
}

function* handelAddOrEditAddress(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.ADD_EDIT_ADDRESS,
      action.payload
    );
    if (response.status == 200) {
      // successToast(response?.data?.message);
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(false, true, error);
    console.log(error);
  }
}

function* handelDeleteAddress(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.DELETE_ADDRESS,
      action.payload
    );
    if (response.status == 200) {
      // successToast(response?.data?.message);
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(false, true, error);
    console.log(error);
  }
}

function* getAllAddressOrderCheckout(action) {
  let { callback } = action;
  callback && callback([], true, false);
  try {
    const response = yield call(apiGet, pathObj.ALL_ADDRESS);
    if (response.status == 200) {
      callback && callback(response?.data?.data, false, false);
    } else {
      callback && callback([], false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback([], false, true);
  }
}

function* getNearestAddress(action) {
  let { callback } = action;
  let { params } = action.payload;
  callback && callback(null, true, true);
  // console.log("ACTION", action.payload);
  try {
    const response = yield call(apiGet, pathObj.NEAREST_ADDRESS, params);
    if (response.status == 200) {
      callback && callback(response?.data?.data, false, false);
    } else {
      callback && callback(null, false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    console.log(error);
  }
}

export function* AddressWatcher() {
  yield takeLatest("GET_ALL_ADDRESS", getAllAddress);
  yield takeLatest("GET_PRIMARY_ADDRESS", handelSetPrimaryAddress);
  yield takeLatest("GET_ZONE", getAllZone);
  yield takeLatest("GET_ADD_EDIT_ADDRESS", handelAddOrEditAddress);
  yield takeLatest("GET_DELETE_ADDRESS", handelDeleteAddress);
  yield takeLatest(
    "GET_ALL_ADDRESS_ORDER_CHECKOUT",
    getAllAddressOrderCheckout
  );
  yield takeLatest("GET_NEAREST_ADDRESS", getNearestAddress);
}
