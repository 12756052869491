import { takeLatest, call, put } from "redux-saga/effects";
import { pathObj } from "../../services/apiPath";
import { apiGet, apiPost } from "services/httpServices";
import { successToast } from "utils/toastMessage";

function* getState(action) {
  try {
    const response = yield call(apiGet, pathObj.GET_STATE_DATA);
    if (response.status == 200) {
      const states = response.data.data;
      const cities = [];
      if (states?.length !== 0) {
        states?.map((item, i) => {
          if (item?.cities) {
            item?.cities?.map((c, index) => {
              cities.push({
                id: item.id.toString() + index.toString(),
                orignalId: item.id,
                name: c.city_name,
                position: { lat: Number(c.lat), lng: Number(c.lng) },
              });
            });
          }
        });
      }
      yield put({
        type: "SET_STATE_INFO",
        payload: { states, cities },
      });
    } else {
      console.log("Something went wrong");
    }
  } catch (error) {
    console.log(error);
  }
}

function* getCityData(action) {
  let { callback } = action;
  callback && callback(null, true, false);
  try {
    const response = yield call(apiGet, pathObj.GET_CITY, action.payload);
    if (response.status === 200) {
      callback && callback(response.data, false, false);
      yield put({
        type: "SET_CITY_DETAILS",
        payload: response.data.data,
      });
    } else {
      callback && callback(null, false, true);
    }
  } catch (error) {
    callback && callback(null, false, true);
  }
}

function* handelSubscribe(action) {
  let { callback } = action;
  callback && callback(null, true, true);

  try {
    const response = yield call(apiPost, pathObj.SUBSCRIBE, action.payload);
    if (response.data.success) {
      // successToast(response?.data?.message);
      callback && callback(response.status, false, false);
    } else {
      callback && callback(response.status, false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(null, false, true, error);
    console.log(error);
  }
}

function* getCms(action) {
  let { callback } = action;
  callback && callback(null, true, false);
  try {
    const response = yield call(apiGet, pathObj.GET_CMS + action.payload);
    if (response.status == 200) {
      callback && callback(response.data, false, false);
    } else {
      callback && callback(null, false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    console.log(error);
  }
}

function* handelContactUs(action) {
  let { callback } = action;
  callback && callback(true, false);
  try {
    const response = yield call(apiPost, pathObj.CONTACT_US, action.payload);
    if (response.status == 200) {
      // successToast(response?.data?.message);
      callback && callback(false, false);
    } else {
      callback && callback(false, false);
      console.log("Something went wrong");
    }
  } catch (error) {
    console.log(error);
  }
}

function* getFaq(action) {
  let { callback } = action;
  callback && callback(null, true, true);
  try {
    const response = yield call(apiGet, pathObj.FAQ);
    if (response.status == 200) {
      callback && callback(response?.data, false, false);
    } else {
      callback && callback(null, false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    console.log(error);
  }
}

function* getRestaurantsOnHeader(action) {
  let { callback } = action;
  let { params } = action.payload;
  callback && callback(true, true);
  // console.log("ACTION", action.payload);
  try {
    const response = yield call(apiGet, pathObj.RESTAURANTS, params);
    if (response.status == 200) {
      callback && callback(false, false);

      if (response?.data?.data.is_previous_date) {
        yield put({
          type: "GET_ASAP_ADVANCE_TYPE",
          payload: 1,
        });
        yield put({
          type: "ASAP_DATE",
          payload: "",
        });
        yield put({
          type: "GET_TIME",
          payload: {},
        });
        yield put({
          type: "GET_DATE_TIME_ON_MENU",
          payload: "",
        });
        // yield put({
        //   type: 'SET_RESTAURANT_LIST_HEADER',
        //   payload: []
        // })
        // localStorage.removeItem('access_token')
        // localStorage.removeItem('myStore')
        // window.location.href = '/sign-in'
      } else {
        yield put({
          type: "SET_RESTAURANT_LIST_HEADER",
          payload: response?.data?.data,
        });
      }
    } else {
      callback && callback(false, true);
      yield put({
        type: "SET_RESTAURANT_LIST_HEADER",
        payload: [],
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    yield put({
      type: "SET_RESTAURANT_LIST_HEADER",
      payload: [],
    });
    console.log(error);
  }
}

function* getHeaderNotification(action) {
  let { callback } = action;
  let { params } = action.payload;
  callback && callback(true, false);
  try {
    const response = yield call(apiGet, pathObj.NOTIFICATION, params);
    if (response.status == 200) {
      callback && callback(false, false);
      yield put({
        type: "SET_HEADER_NOTIFICATION",
        payload: response?.data?.data,
      });
    } else {
      callback && callback({}, false, true);
      yield put({
        type: "SET_HEADER_NOTIFICATION",
        payload: [],
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback({}, false, true);
    yield put({
      type: "SET_HEADER_NOTIFICATION",
      payload: [],
    });
  }
}

function* handelUpdateLatOrLng(action) {
  let { callback } = action;
  callback && callback(true, true);

  try {
    const response = yield call(
      apiPost,
      pathObj.UPDATE_LAT_LNG,
      action.payload
    );
    if (response.data.success) {
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    console.log(error);
  }
}

function* getIsNotificationExist(action) {
  let { callback } = action;
  callback && callback(null, true, false);
  try {
    const response = yield call(apiGet, pathObj.NOTIFICATION_EXIST);
    if (response.status == 200) {
      callback && callback(response.data, false, false);
      yield put({
        type: "SET_NOTIFICATION_EXIST",
        payload: response?.data?.data?.is_unread_notification_exist,
      });
    } else {
      callback && callback(null, false, true);
      yield put({
        type: "SET_NOTIFICATION_EXIST",
        payload: 0,
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    yield put({
      type: "SET_NOTIFICATION_EXIST",
      payload: 0,
    });
  }
}

function* handelReadNotification(action) {
  let { callback } = action;
  callback && callback(null, true, true);

  try {
    const response = yield call(
      apiPost,
      pathObj.READ_NOTIFICATION,
      action.payload
    );
    if (response.data.success) {
      callback && callback(response.status, false, false);
    } else {
      callback && callback(response.status, false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(null, false, true);
    console.log(error);
  }
}

export function* HomeWatcher() {
  yield takeLatest("GET_STATE_INFO", getState);
  yield takeLatest("GET_CITY_DETAILS", getCityData);
  yield takeLatest("GET_SUBSCRIPTION", handelSubscribe);
  yield takeLatest("GET_CMS", getCms);
  yield takeLatest("GET_CONTACT_US", handelContactUs);
  yield takeLatest("GET_FAQ", getFaq);
  yield takeLatest("GET_RESTAURANT_LIST_HEADER", getRestaurantsOnHeader);
  yield takeLatest("GET_HEADER_NOTIFICATION", getHeaderNotification);
  yield takeLatest("GET_UPDATE_LAT_LNG", handelUpdateLatOrLng);
  yield takeLatest("GET_NOTIFICATION_EXIST", getIsNotificationExist);
  yield takeLatest("SET_NOTIFICATION_READ", handelReadNotification);
}
