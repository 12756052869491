export const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const BASE_URL_IMAGE = process.env.REACT_APP_BASE_IMAGE_URL;
export const SOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
export const REACT_APP_BASE_GC_IMAGE_URL =
  process.env.REACT_APP_BASE_GC_IMAGE_URL;
// export const FOOD_DUDES_CAPTCHA = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
export const FOOD_DUDES_CAPTCHA = "6Lefxe4mAAAAAJ0Ud1w0TBO8U8VAHRQdm3UJ_AGu";
export const DEVELOPMENT_PRODUCTION = 453;
export const GOOGLE_MERCHANT_KEY = "BCR2DN4T52O4HWJQ";
export const VENMO_PROFILE_ID = "3819325392004936025";
export const SOCKET_CUSTOMER = process.env.REACT_APP_SOCKET_CUSTOMER;
// export const DEVELOPMENT_PRODUCTION = 1997;
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
