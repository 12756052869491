import React from "react";
import Lottie from "react-lottie";
import SuccessCheckMark from "assets/lottiAnimation/404.json";

export default function NotFound({
  loader,
  height,
  width,
  message,
  previousMessage,
  linkTo,
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: SuccessCheckMark,
    // animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <Lottie
        options={defaultOptions}
        height={height ?? 120}
        width={width ?? 120}
      />
      <p
        style={{
          color: "#5c6873",
          fontWeight: "600",
          textAlign: "center",
          marginTop: "20px",
          fontSize: "20px",
        }}
      >
        {message ?? ""}
      </p>
    </>
  );
}
