import { combineReducers } from "redux";
import HomeReducer from "./HomeReducer";
import AuthReducer from "./AuthReducer";
import ProfileReducer from "./ProfileReducer";
import ResturantReducer from "./ResturantReducer";
import CheckoutReducer from "./CheckoutReducer";
import AddressReducer from "./Sidebar/AddressReducer";
import RasturantMenuReducer from "./RasturantMenuReducer";
import OrderReducer from "./Sidebar/OrderReducer";
import RewardReducer from "./Sidebar/RewardsReducer";
import GiftCardReducer from "./Sidebar/GiftCardReducer";
import PurchaseGiftCardReducer from "./Sidebar/PurchaseGiftCardReducer";

const appReducer = combineReducers({
  HomeReducer,
  AuthReducer,
  ProfileReducer,
  ResturantReducer,
  CheckoutReducer,
  AddressReducer,
  RasturantMenuReducer,
  OrderReducer,
  RewardReducer,
  GiftCardReducer,
  PurchaseGiftCardReducer,
});

const rootReducer = (state, action) => {
  if (action.type == "LOGOUT") {
    localStorage.removeItem("myStore");
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
