import { takeLatest, call, put } from "redux-saga/effects";
import { pathObj } from "../../../services/apiPath";
import { apiGet, apiPost } from "services/httpServices";
import { successToast } from "utils/toastMessage";

function* fetchOrderListing(action) {
  let { callback } = action;
  callback && callback(null, true, true);
  try {
    const response = yield call(apiPost, pathObj.ORDER_LIST, action.payload);
    if (response.status == 200) {
      yield put({
        type: "SET_ORDER_LISTING",
        payload: response?.data?.data,
      });
      callback && callback(response?.data, false, false);
    } else {
      callback && callback(null, false, true);
      yield put({
        type: "SET_ORDER_LISTING",
        payload: [],
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(null, false, true);
    yield put({
      type: "SET_ORDER_LISTING",
      payload: [],
    });
    console.log(error);
  }
}
function* fetchOrderDetails(action) {
  let { callback } = action;
  callback && callback(null, true, true);
  try {
    const response = yield call(apiPost, pathObj.ORDER_DETAILS, action.payload);
    if (response.status == 200) {
      yield put({
        type: "SET_ORDER_DETAILS",
        payload: response?.data?.data,
      });
      callback && callback(response?.data, false, false);
    } else {
      callback && callback(null, false, true);
      yield put({
        type: "SET_ORDER_DETAILS",
        payload: {},
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(null, false, true);
    yield put({
      type: "SET_ORDER_DETAILS",
      payload: {},
    });
    console.log(error);
  }
}
function* getOrderTracking(action) {
  let { callback } = action;
  callback && callback(null, true, true, false);
  try {
    const response = yield call(
      apiGet,
      pathObj.ORDER_TRACKING + "/" + action.payload
    );
    if (response.status == 200) {
      callback && callback(response?.data, false, false, false);
    } else {
      callback && callback(null, false, true, false);
    }
  } catch (error) {
    callback && callback(null, false, true, error);
  }
}

function* handelCancelOrder(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(apiPost, pathObj.CANCEL_ORDER, action.payload);
    if (response.status == 200) {
      // successToast(response?.data?.message);
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true, error);
    console.log(error);
  }
}

function* handelGenerateInvoice(action) {
  let { callback } = action;
  callback && callback(true, null);
  try {
    const response = yield call(
      apiPost,
      pathObj.GENERATE_INVOICE,
      action.payload
    );
    if (response.status == 200) {
      // successToast(response?.data?.message);
      callback && callback(false, response?.data);
    }
  } catch (error) {
    callback && callback(false, null, error);
    console.log(error);
  }
}

function* handelReOrder(action) {
  let { callback } = action;
  callback && callback(null, true, true);
  try {
    const response = yield call(apiPost, pathObj.RE_ORDER, action.payload);
    if (response.status == 200) {
      callback && callback(response.data, false, false);
      yield put({
        type: "SET_RE_ORDER",
        payload: response?.data?.data,
      });
    } else {
      callback && callback(null, false, true);
      console.log("Something went wrong");
      yield put({
        type: "SET_RE_ORDER",
        payload: {},
      });
    }
  } catch (error) {
    callback && callback(null, false, true, error);
    yield put({
      type: "SET_RE_ORDER",
      payload: {},
    });
    console.log(error);
  }
}

export function* OrderWatcher() {
  yield takeLatest("GET_ORDER_LISTING", fetchOrderListing);
  yield takeLatest("GET_ORDER_DETAILS", fetchOrderDetails);
  yield takeLatest("GET_ORDER_TRACKING", getOrderTracking);
  yield takeLatest("GET_CANCEL_ORDER", handelCancelOrder);
  yield takeLatest("GET_RE_ORDER", handelReOrder);
  yield takeLatest("GET_GENERATE_INVOICE", handelGenerateInvoice);
}
