import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./orderDetail.css";
import "./orderDetailMedia.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_CANCEL_ORDER,
  GET_GENERATE_INVOICE,
  GET_ORDER_DETAILS,
  GET_RE_ORDER,
  GET_RE_ORDER_KEY,
  SET_ORDER_DETAILS,
} from "store/Action/Sidebar/OrderAction";
import LottiLoader from "components/shared/Loader/LottiLoader";
import { textTruncateMore } from "helpers/textTruncate/TextTruncate";
import moment from "moment";
import { Skeleton } from "@mui/material";
import FeeAndSalesTooltip from "components/Checkout/OrderCheckout/FeeAndSalesTooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OrderDetailMap from "./OrderDetailMap";
import CancelOrderModal from "./CancelOrderModal";
import crossImage from "assets/images/alert-cross.svg";
import Loader from "components/shared/Loader/Loader";
import {
  ASAP_DATE,
  GET_ASAP_ADVANCE_TYPE,
  GET_SELECTED_RESTAURANT_DATA,
  GET_SERVICE_TYPE,
  GET_TIME,
} from "store/Action/RasturantAction";
import { DROP_OPTION } from "store/Action/CheckoutAction";
//import io from "socket.io-client";
//import { SOCKET_CUSTOMER } from "services/path";
import ReactHelmet from "components/shared/ReactHelmet/ReactHelmet";
import AlertMessage from "components/AlertMessage/AlertMessage";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import DownloadingOutlinedIcon from "@mui/icons-material/DownloadingOutlined";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const OrderDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const OrderId = params.id;
  const navigate = useNavigate();
  const Token = localStorage.getItem("access_token");
  const isMobileApp = localStorage.getItem("is_mobile_app");
  // ALL SELECTORS
  const { ORDER_DETAILS, USER_INFO } = useSelector((store) => ({
    ORDER_DETAILS: store?.OrderReducer?.orderDetails ?? {},
    USER_INFO: store?.AuthReducer?.userInfo?.data ?? {},
  }));

  // ALL STATE
  const [loader, setLoader] = useState(false);
  const [showCancelOrder, setShowCancelOrder] = useState(false);
  const [cancelOrderLoader, setCancelOrderLoader] = useState(false);
  const [reOrderLoader, setReOrderLoader] = useState(false);
  const [orderType, setOrderType] = useState("");
  const [showMoreMessage, setShowMoreMessage] = useState(false);
  const [socket, setSocket] = useState(null);
  const [orderSocketData, setOrderSocketData] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [open, setOpen] = useState(true);
  const [errorReorder, setErrorReorder] = useState(null);
  const [openReorder, setOpenReorder] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleReorderClose = () => {
    setOpenReorder(false);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // useEffect(() => {
  //   setOrderSocketData(ORDER_DETAILS);
  // }, []);

  useEffect(() => {
    const resultPayload = { order_id: OrderId };
    dispatch(
      GET_ORDER_DETAILS(resultPayload, (res, loading, error) => {
        setLoader(loading);
        if (!error) {
          if (res?.data) {
            setOrderSocketData(res?.data);
          }
          if (res?.data?.product_summary?.length) {
            const temp = res?.data?.product_summary[0];
            const resultOrderType =
              temp?.products_model == "OTHER_GIFT_CARD"
                ? "giftCard"
                : temp?.products_model == "GIFT_CARD"
                ? "giftCard"
                : temp?.products_model == "GIFT_CARD_NON_REWARDS"
                ? "giftCard"
                : temp?.products_model == "E_GIFT_CARD"
                ? "eGiftCard"
                : temp?.products_model == "E_GIFT_CARD_NON_REWARDS"
                ? "eGiftCard"
                : temp?.products_model == "ADD_WALLET"
                ? "wallet"
                : "";

            setOrderType(resultOrderType);
          }
        }
      })
    );
  }, [OrderId]);

  const renderFirstLevel = (item) => {
    console.log("item", item);
    const firstLevelProductName = item?.parent_attribute?.map((el) => {
      if (el?.quantity > 1) {
        return `${el?.quantity}x${el?.products_options_values}`;
      }
      return el?.products_options_values;
    });
    const resultFirstLevel = firstLevelProductName.join(",");
    return resultFirstLevel ? resultFirstLevel : "";
  };

  const renderSecondLevel = (item) => {
    const secondLevelLength = item?.parent_attribute.some(
      (al) => al?.child_attribute?.length
    );
    if (secondLevelLength) {
      let arr = [];
      const secondLevelProductName = item?.parent_attribute?.map((el) => {
        if (el?.child_attribute?.length !== 0) {
          el?.child_attribute?.map((rt) => {
            arr.push(rt?.products_options_values);
          });
        }
      });
      const resultSecondLevel = arr?.length ? arr?.join(",") : "";
      console.log("resultSecondLevel111", resultSecondLevel, item);

      return resultSecondLevel ? resultSecondLevel : "";
    }
  };

  const toggleCancelOrder = () => {
    setShowCancelOrder(!showCancelOrder);
  };

  const handelCancelOrder = () => {
    const resultPayload = { order_id: OrderId };
    dispatch(
      GET_CANCEL_ORDER(resultPayload, (loading, error, errObj) => {
        if (error) {
          setOpen(error);
          setErrorMessage(errObj);
        }
        setCancelOrderLoader(loading);
        if (!error) {
          setShowCancelOrder(false);
          dispatch(
            GET_ORDER_DETAILS(resultPayload, (res, loading, error) => {
              setLoader(loading);
              if (!error) {
              }
            })
          );
        }
      })
    );
  };

  const handelReorder = () => {
    const resultPayload = { order_id: OrderId };
    dispatch(
      GET_RE_ORDER(resultPayload, (res, loading, error, errObj) => {
        if (error) {
          setOpenReorder(error);
          setErrorReorder(errObj);
        }
        setReOrderLoader(loading);
        if (!error) {
          dispatch(GET_RE_ORDER_KEY(true));
          if (res?.data?.mode == "Delivery") {
            dispatch(GET_SERVICE_TYPE(1));
            dispatch(
              DROP_OPTION(res?.data?.delivery_instructions ?? "Hand to me")
            );
            dispatch(GET_ASAP_ADVANCE_TYPE(1));
            dispatch(ASAP_DATE(""));
            dispatch(GET_TIME({}));
            dispatch(
              GET_SELECTED_RESTAURANT_DATA({
                id: res?.data?.restaurant_id,
                city_name: res?.data?.city_name,
                restro_name: res?.data?.restro_name,
              })
            );

            // navigate(`/restaurants-menu/${res?.data?.restaurant_id}`);
            navigate(`/${res?.data?.slug}?q=fd`);
          } else if (res.data.mode == "Pickup") {
            dispatch(GET_SERVICE_TYPE(2));
            dispatch(
              DROP_OPTION(res?.data?.delivery_instructions ?? "Hand to me")
            );
            dispatch(GET_ASAP_ADVANCE_TYPE(1));
            dispatch(ASAP_DATE(""));
            dispatch(GET_TIME({}));
            dispatch(
              GET_SELECTED_RESTAURANT_DATA({
                id: res?.data?.restaurant_id,
                city_name: res?.data?.city_name,
                restro_name: res?.data?.restro_name,
              })
            );
            // dispatch(GET_SELECTED_RESTAURANT_DATA(res?.data));
            // navigate(`/restaurants-menu/${res?.data?.restaurant_id}`);
            navigate(`/${res?.data?.slug}?q=fd`);
          }
        }
      })
    );
  };

  const handelShowMoreMessage = () => {
    setShowMoreMessage(!showMoreMessage);
  };

  // SOCKET FOR ORDER DETAILS
  /* useEffect(() => {
    const socketBaseUrl = SOCKET_CUSTOMER;
    const newSocket = io.connect(`${socketBaseUrl}`);

    console.log("Socket", newSocket);

    const OrderListener = (data) => {
      console.log("socket data", data);

      if (OrderId == data?.order_id) {
        setOrderSocketData((prevState) => ({
          ...prevState,
          order_status: data.order_status,
          delivery_message: data.delivery_message,
        }));
      }
    };

    newSocket.emit("user_connected", USER_INFO?.customers_id);

    newSocket.off("order-updates").on("order-updates", (data) => {
      OrderListener(data);
    });
    setSocket(newSocket);
    return () => newSocket.close();
  }, []); */

  const [loading, setLoading] = useState(false);
  const downLoadReceipt = () => {
    const resultPayload = { order_id: OrderId };
    dispatch(
      GET_GENERATE_INVOICE(resultPayload, (loading, res, error) => {
        setLoading(loading);
        console.log("res", res);
        if (res?.data?.pdf_url) {
          fetch(res?.data?.pdf_url, {
            method: "GET",
            headers: {
              "Content-Type": "application/pdf",
            },
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.blob();
            })
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `Receipt.pdf`);
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
              console.error("There was an error!", error);
            });
        }
      })
    );
  };

  useEffect(() => {
    if (Object.keys(orderSocketData).length) {
      dispatch(SET_ORDER_DETAILS(orderSocketData));
    }
  }, [orderSocketData]);

  const totalCartItem = () => {
    let total = 0;
    console.log(
      "ORDER_DETAILS?.product_summary",
      ORDER_DETAILS?.product_summary
    );
    for (const cart of ORDER_DETAILS?.product_summary) {
      total += cart.products_quantity;
    }

    return total;
  };

  return (
    <div className="main-container">
      <ReactHelmet />
      <section className="order-detail-sec section_space">
        {loader ? (
          <Container className=" d-flex align-items-center default-back">
            <LottiLoader loader={true} />
          </Container>
        ) : (
          <>
            <Container>
              <span
                className="mb-sm-4 mb-2 d-none d-md-inline-block back-btn"
                style={{ marginRight: "15px" }}
                onClick={() => navigate("/order")}
              >
                <ArrowBackIcon /> <strong>Back</strong>
              </span>
              {/* <span
                className="mb-sm-4 mb-2 back-btn d-none d-md-inline-block"
                onClick={() => navigate("/restaurants")}
              >
                <StorefrontIcon />
                &nbsp;
                <strong>Restaurants</strong>
              </span> */}
            </Container>

            {Object.keys(ORDER_DETAILS).length !== 0 ? (
              <Container className="load-with-animation-medium">
                {errorReorder && (
                  <AlertMessage
                    severity="error"
                    icon={false}
                    message={errorReorder?.response?.data?.error[0]}
                    isOpen={openReorder}
                    onClose={handleReorderClose}
                    className={"mb-3"}
                    autoCloseDuration={3000}
                  />
                )}
                <div className="common-heading d-flex align-items-center justify-content-between mb-4 mt-0 mt-sm-2">
                  <div className="d-flex align-items-center">
                    <span
                      className="mb-0 mb-md-2 back-btn d-inline-block d-md-none"
                      style={{ marginRight: "15px" }}
                      onClick={() => navigate("/order")}
                    >
                      <ArrowBackIcon />
                      {/* <strong>Back</strong> */}
                    </span>

                    <h2 className="mb-0 mb-md-2">Order Details</h2>
                  </div>

                  {!orderType && (
                    <>
                      {/* {ORDER_DETAILS.order_status == 'Pending' ||
                      ORDER_DETAILS.order_status == 'Scheduled' ? (
                        <Button
                          className='common-btn px-4 py-2 mobile_small_btn'
                          onClick={() => toggleCancelOrder()}
                        >
                          Cancel Order
                        </Button>
                      ) : null} */}
                      {/* {ORDER_DETAILS?.hide_cancel_button ? null : (
                        <Button
                          className="common-btn px-4 py-2 mobile_small_btn cancel-btn"
                          onClick={() => toggleCancelOrder()}
                        >
                          Cancel Order
                        </Button>
                      )} */}
                    </>
                  )}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "end",
                    }}
                  >
                    {ORDER_DETAILS.restaurant_id !== 453 &&
                      isMobileApp !== "yes" &&
                      ORDER_DETAILS.order_status !== "Canceled" && (
                        <p
                          title="Download Receipt"
                          style={{ marginRight: "10px" }}
                        >
                          <Button
                            className="common-btn px-3 py-2   mobile_small_btn"
                            style={{ display: "flex" }}
                            disabled={loading}
                            onClick={() => downLoadReceipt()}
                          >
                            <span className="d-flex align-item-center justify-content-center">
                              Receipt
                            </span>
                          </Button>
                        </p>
                      )}

                    {!orderType && (
                      <>
                        {ORDER_DETAILS?.mode === "Pickup" ? (
                          <>
                            {ORDER_DETAILS?.order_status === "Delivered" ||
                            ORDER_DETAILS?.order_status === "Completed" ||
                            ORDER_DETAILS?.order_status === "Picked-Up" ? (
                              <Button
                                className="common-btn px-4 py-2 mobile_small_btn"
                                style={
                                  reOrderLoader ? { cursor: "not-allowed" } : {}
                                }
                                onClick={() => handelReorder()}
                              >
                                <span className="d-flex align-item-center justify-content-center">
                                  Reorder
                                  {reOrderLoader && (
                                    <Loader
                                      Style={{
                                        // height: "25px",
                                        // width: "25px",
                                        marginLeft: "15px",
                                      }}
                                      classes="widthtwintyFive"
                                    />
                                  )}
                                </span>
                              </Button>
                            ) : null}
                          </>
                        ) : (
                          <>
                            {ORDER_DETAILS?.order_status === "Delivered" ||
                            ORDER_DETAILS?.order_status === "Completed" ? (
                              <Button
                                className="common-btn px-3 py-2 mobile_small_btn"
                                style={
                                  reOrderLoader ? { cursor: "not-allowed" } : {}
                                }
                                onClick={() => handelReorder()}
                              >
                                <span className="d-flex align-item-center justify-content-center">
                                  Reorder
                                  {reOrderLoader && (
                                    <Loader
                                      Style={{
                                        // height: "25px",
                                        // width: "25px",
                                        marginLeft: "15px",
                                      }}
                                      classes="widthtwintyFive"
                                    />
                                  )}
                                </span>
                              </Button>
                            ) : null}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {/* Order Delayed start */}
                {ORDER_DETAILS?.order_delay_message && (
                  <div
                    className="order-detail-cancel"
                    style={{ display: "revert" }}
                  >
                    <div className="d-flex">
                      <span className="w-100 d-flex justify-content-center ">
                        <p className="mb-0 order-detail-cancel-para fw-bold">
                          {ORDER_DETAILS?.order_delay_message}
                        </p>
                      </span>
                    </div>
                  </div>
                )}
                {/* Order Delayed end */}
                {/* Order Related Message */}
                {/* {!showMoreMessage ? (
                  <div
                    className='order-detail-cancel'
                    onClick={() => handelShowMoreMessage()}
                    style={{ display: 'revert' }}
                  >
                    <div className='d-flex'>
                      <span className='w-100 d-flex justify-content-center '>
                        <p className='mb-0 order-detail-cancel-para fw-bold'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </p>
                      </span>
                      <KeyboardArrowDownIcon />
                    </div>
                  </div>
                ) : (
                  <div
                    className='order-detail-cancel position-relative'
                    style={{ display: 'revert' }}
                    onClick={() => handelShowMoreMessage()}
                  >
                    <KeyboardArrowUpIcon className='position-absolute end-0 me-4 ' />
                    {Array(5)
                      .fill('')
                      .map(index => {
                        return (
                          <>
                            <div className='d-flex'>
                              <span className='w-100 d-flex justify-content-center '>
                                <p className='mb-0 order-detail-cancel-para fw-bold'>
                                  It is a long established fact that a reader
                                  will be distracted by the readable content of
                                  a page when looking at its layout.
                                </p>
                              </span>
                            </div>
                          </>
                        )
                      })}
                  </div>
                )} */}

                {/* Order Related Message */}

                {ORDER_DETAILS?.order_status === "Canceled" ? (
                  <div className="order-detail-cancel">
                    <img src={crossImage} alt="No_Image" />
                    <span className="w-100 d-flex justify-content-center">
                      {ORDER_DETAILS?.payment_method === "Cash" ? (
                        <p className="mb-0 order-detail-cancel-para fw-bold">
                          This order is canceled.
                        </p>
                      ) : (
                        <p className="mb-0 order-detail-cancel-para fw-bold">
                          This order is canceled. The amount has been refunded
                          to your payment method.
                        </p>
                      )}
                    </span>
                  </div>
                ) : (
                  <div className="deliver-progress text-center d-none d-md-block">
                    <h4>{ORDER_DETAILS?.order_status ?? ""}</h4>

                    <div className="track_detail">
                      <ul className="progressBar">
                        <li
                          className={
                            ORDER_DETAILS?.order_status === "Pending" ||
                            ORDER_DETAILS?.order_status === "Scheduled" ||
                            ORDER_DETAILS?.order_status === "Accepted" ||
                            ORDER_DETAILS?.order_status === "Picked-Up" ||
                            ORDER_DETAILS?.order_status === "Delivered" ||
                            ORDER_DETAILS?.order_status === "Completed" ||
                            ORDER_DETAILS?.order_status === "Ready"
                              ? "success-booking-one success-booking"
                              : ""
                          }
                        >
                          <span className="track_circle"></span>
                          <span className="status_name">Pending</span>
                        </li>
                        {!orderType && (
                          <li
                            className={
                              ORDER_DETAILS?.order_status === "Accepted" ||
                              ORDER_DETAILS?.order_status === "Picked-Up" ||
                              ORDER_DETAILS?.order_status === "Delivered" ||
                              ORDER_DETAILS?.order_status === "Completed" ||
                              ORDER_DETAILS?.order_status === "Ready"
                                ? "success-booking-one success-booking"
                                : ""
                            }
                          >
                            <span className="track_circle"></span>
                            <span className="status_name">Accepted</span>
                          </li>
                        )}
                        {!orderType &&
                          (ORDER_DETAILS?.mode === "Pickup" ? (
                            <li
                              className={
                                ORDER_DETAILS?.order_status === "Picked-Up" ||
                                ORDER_DETAILS?.order_status === "Delivered" ||
                                ORDER_DETAILS?.order_status === "Completed" ||
                                ORDER_DETAILS?.order_status === "Ready"
                                  ? "success-booking-one success-booking"
                                  : ""
                              }
                            >
                              <span className="track_circle"></span>
                              <span className="status_name">Ready</span>
                            </li>
                          ) : (
                            <li
                              className={
                                ORDER_DETAILS?.order_status === "Picked-Up" ||
                                ORDER_DETAILS?.order_status === "Delivered" ||
                                ORDER_DETAILS?.order_status === "Completed" ||
                                ORDER_DETAILS?.order_status === "Ready"
                                  ? "success-booking-one success-booking"
                                  : ""
                              }
                            >
                              <span className="track_circle"></span>
                              <span className="status_name">Picked-Up</span>
                            </li>
                          ))}
                        {ORDER_DETAILS?.mode === "Pickup" ? (
                          <li
                            className={
                              ORDER_DETAILS?.order_status === "Completed" ||
                              ORDER_DETAILS?.order_status === "Delivered" ||
                              ORDER_DETAILS?.order_status === "Picked-Up"
                                ? // ORDER_DETAILS?.order_status === "Ready" ||
                                  "success-booking-one success-booking"
                                : ""
                            }
                          >
                            <span className="track_circle"></span>
                            <span className="status_name">Completed</span>
                          </li>
                        ) : (
                          <>
                            {orderType ? (
                              <li
                                className={
                                  ORDER_DETAILS?.order_status === "Completed" ||
                                  ORDER_DETAILS?.order_status === "Delivered"
                                    ? "success-booking-one success-booking"
                                    : ""
                                }
                              >
                                <span className="track_circle"></span>
                                <span className="status_name">Completed</span>
                              </li>
                            ) : (
                              <li
                                className={
                                  ORDER_DETAILS?.order_status === "Completed" ||
                                  ORDER_DETAILS?.order_status === "Delivered"
                                    ? "success-booking-one success-booking"
                                    : ""
                                }
                              >
                                <span className="track_circle"></span>
                                <span className="status_name">Delivered</span>
                              </li>
                            )}
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
                <div>
                  <Row className="flex-column-reverse flex-lg-row justify-content-between">
                    <Col lg={7}>
                      <div className="order-detail-wrapper fix_detail_content">
                        <div className="order-detail">
                          <span>Order Number</span>
                          <strong>{ORDER_DETAILS?.order_id ?? ""}</strong>
                        </div>
                        {ORDER_DETAILS?.restaurant_name && (
                          <div className="order-detail">
                            <span>Restaurant Name</span>
                            <strong
                              title={ORDER_DETAILS?.restaurant_name}
                              style={
                                !orderType
                                  ? { color: "#F06F00", cursor: "pointer" }
                                  : {}
                              }
                              onClick={() => {
                                if (!orderType) {
                                  // navigate(
                                  //   `/restaurants-menu/${ORDER_DETAILS?.restaurant_id}`
                                  // );
                                  dispatch(
                                    GET_SELECTED_RESTAURANT_DATA({
                                      id: ORDER_DETAILS.restaurant_id,
                                      city_name: ORDER_DETAILS?.city_name,
                                      restro_name: ORDER_DETAILS?.restro_name,
                                    })
                                  );
                                  navigate(
                                    `/${ORDER_DETAILS?.restaurant_slug}`
                                  );
                                  // navigate(
                                  //   `/${ORDER_DETAILS?.city_name}/${ORDER_DETAILS?.restro_name}`
                                  // );
                                }
                              }}
                            >
                              {textTruncateMore(
                                ORDER_DETAILS?.restaurant_name,
                                30
                              ) ?? ""}
                            </strong>
                          </div>
                        )}
                        {ORDER_DETAILS?.order_deliver_time && (
                          <div className="order-detail">
                            <span>
                              {ORDER_DETAILS?.mode === "Pickup"
                                ? "Pickup "
                                : "Delivery "}
                              Time
                            </span>
                            <strong>
                              {moment(ORDER_DETAILS?.order_deliver_time).format(
                                "ddd, MM/DD/yyyy h:mm A"
                              )}
                            </strong>
                          </div>
                        )}
                        {ORDER_DETAILS?.address &&
                          ORDER_DETAILS?.address !== "  " && (
                            <div className="position-relative">
                              <div className="order-detail">
                                <span>
                                  {" "}
                                  {ORDER_DETAILS?.mode === "Pickup"
                                    ? "Restaurant"
                                    : "Delivery"}{" "}
                                  Address
                                </span>
                                <strong title={ORDER_DETAILS?.address}>
                                  {/* {textTruncateMore(
                                    ORDER_DETAILS?.address,
                                    30
                                  ) ?? ""} */}
                                  {ORDER_DETAILS?.address}
                                </strong>
                              </div>{" "}
                              {ORDER_DETAILS?.delivery_instructions && (
                                <span className="meet_at_door">
                                  {ORDER_DETAILS?.delivery_instructions}
                                </span>
                              )}
                            </div>
                          )}
                        <div className="order-details-wrapper">
                          <h2>Order details ({totalCartItem()} Item)</h2>

                          <div className="shopping-cart-items">
                            <ul>
                              {ORDER_DETAILS?.product_summary &&
                                ORDER_DETAILS?.product_summary?.length !== 0 &&
                                ORDER_DETAILS?.product_summary?.map(
                                  (data, index) => {
                                    return (
                                      <li key={index} className="d-flex">
                                        <div className="item-quantity">
                                          <span className="quantity-count-order-Detail">
                                            {data?.products_quantity}x
                                          </span>
                                        </div>
                                        <div className="cart-item pt-1 reduce_for_price">
                                          <p
                                            className="item-name"
                                            title={data?.products_name}
                                          >
                                            {/* {textTruncateMore(
                                              data?.products_name,
                                              21
                                            ) ?? ""} */}
                                            {data?.products_name}
                                          </p>
                                          {orderType === "eGiftCard" && (
                                            <>
                                              {data.parent_attribute &&
                                                data.parent_attribute.length !==
                                                  0 &&
                                                data.parent_attribute.map(
                                                  (el) => {
                                                    return (
                                                      <p className="fs-6 text-secondary mb-0">
                                                        <strong>
                                                          {el?.products_options.replace(
                                                            "Amount",
                                                            ""
                                                          )}
                                                        </strong>
                                                        {el?.products_options ===
                                                        "Amount"
                                                          ? " "
                                                          : ":"}{" "}
                                                        <span
                                                          title={
                                                            el?.products_options_values
                                                          }
                                                          style={{
                                                            fontSize: "16px",
                                                          }}
                                                        >
                                                          {textTruncateMore(
                                                            el?.products_options_values,
                                                            245
                                                          )}
                                                          {/* {
                                                            el?.products_options_values
                                                          } */}
                                                        </span>
                                                      </p>
                                                    );
                                                  }
                                                )}
                                            </>
                                          )}
                                          {orderType !== "eGiftCard" && (
                                            <>
                                              {data?.parent_attribute
                                                ?.length !== 0 && (
                                                <p className="fs-6 text-secondary">
                                                  {renderFirstLevel(data)}
                                                </p>
                                              )}

                                              <p className="fs-6">
                                                {renderSecondLevel(data)}
                                              </p>
                                            </>
                                          )}
                                        </div>

                                        <div className="amount_wrap">
                                          {data?.final_price && (
                                            <strong className="item-price">
                                              $
                                              {data.final_price
                                                ? parseFloat(
                                                    data?.final_price
                                                  )?.toFixed(2)
                                                : ""}
                                            </strong>
                                          )}
                                        </div>
                                      </li>
                                    );
                                  }
                                )}
                            </ul>
                          </div>
                        </div>
                        <div className="order-detail-instruct">
                          {ORDER_DETAILS?.special_instructions && (
                            <span>
                              <h2>Special Instructions</h2>
                              <p>{ORDER_DETAILS?.special_instructions ?? ""}</p>
                            </span>
                          )}

                          {/* CODE STATE */}
                          <Fragment>
                            {Object.keys(ORDER_DETAILS?.price_summary)
                              ?.length === 0 ? (
                              <Fragment>
                                <ul>
                                  {Array(5)
                                    .fill("")
                                    .map((i, ind) => {
                                      return (
                                        <li key={ind}>
                                          <span>
                                            <Skeleton
                                              variant="rounded"
                                              width={200}
                                              height={15}
                                              className="mb-2"
                                            />
                                          </span>

                                          <span>
                                            <Skeleton
                                              variant="rounded"
                                              width={80}
                                              height={15}
                                              className="mb-2"
                                            />
                                          </span>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </Fragment>
                            ) : (
                              <ul>
                                {ORDER_DETAILS?.price_summary &&
                                  Object.keys(ORDER_DETAILS?.price_summary)
                                    ?.length !== 0 &&
                                  Object.keys(
                                    ORDER_DETAILS?.price_summary
                                  )?.map((item, index) => {
                                    return (
                                      <Fragment key={index}>
                                        {item !== "Total:" && (
                                          <li>
                                            <span className="d-flex justify-content-start ">
                                              {item}
                                              {item ===
                                                "Fees and sales tax" && (
                                                <FeeAndSalesTooltip
                                                  taxes={
                                                    ORDER_DETAILS
                                                      ?.price_summary[item]
                                                  }
                                                />
                                              )}
                                            </span>{" "}
                                            <span>
                                              {item === "Fees and sales tax"
                                                ? ORDER_DETAILS?.price_summary &&
                                                  Object.keys(
                                                    ORDER_DETAILS
                                                      ?.price_summary[item]
                                                  )?.length &&
                                                  "$" +
                                                    Object.keys(
                                                      ORDER_DETAILS
                                                        ?.price_summary[item]
                                                    )
                                                      .reduce(
                                                        (pre, key) =>
                                                          pre +
                                                          parseFloat(
                                                            ORDER_DETAILS?.price_summary[
                                                              item
                                                            ][key].replace(
                                                              "$",
                                                              ""
                                                            )
                                                          ),
                                                        0
                                                      )
                                                      ?.toFixed(2)
                                                : ORDER_DETAILS?.price_summary[
                                                    item
                                                  ]}
                                            </span>
                                          </li>
                                        )}
                                      </Fragment>
                                    );
                                  })}
                              </ul>
                            )}
                          </Fragment>
                          {/* CODE END */}

                          {ORDER_DETAILS?.price_summary &&
                            Object.keys(ORDER_DETAILS?.price_summary)
                              ?.length !== 0 &&
                            Object.keys(ORDER_DETAILS?.price_summary)?.map(
                              (item, index) => {
                                return (
                                  <Fragment key={index}>
                                    {item === "Total:" && (
                                      <div className="total-wrapper">
                                        <h2>
                                          {item}
                                          <span>
                                            {ORDER_DETAILS?.price_summary[item]}
                                          </span>
                                        </h2>
                                      </div>
                                    )}
                                  </Fragment>
                                );
                              }
                            )}

                          <div className="paid-by">
                            <span>Paid By</span>
                            <strong>
                              {ORDER_DETAILS?.payment_method ?? ""}
                            </strong>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {/* {orderType !== "eGiftCard" && ( */}
                    <Col lg={5}>
                      {!loader && (
                        <>
                          {orderType !== "eGiftCard" &&
                            orderType !== "wallet" && (
                              <div className="text-center map-sec map_space position-relative">
                                {ORDER_DETAILS?.delivery_message && (
                                  <div className="delivery_time">
                                    <p className="mb-0">
                                      {ORDER_DETAILS.delivery_message}
                                    </p>
                                  </div>
                                )}

                                <OrderDetailMap ORDER_DETAILS={ORDER_DETAILS} />
                              </div>
                            )}
                        </>
                      )}
                      {ORDER_DETAILS?.order_status !== "Canceled" && (
                        <div className="deliver-progress text-center d-block d-md-none">
                          <h4>{ORDER_DETAILS?.order_status ?? ""}</h4>

                          <div className="track_detail">
                            <ul className="progressBar">
                              <li
                                className={
                                  ORDER_DETAILS?.order_status === "Pending" ||
                                  ORDER_DETAILS?.order_status === "Scheduled" ||
                                  ORDER_DETAILS?.order_status === "Accepted" ||
                                  ORDER_DETAILS?.order_status === "Picked-Up" ||
                                  ORDER_DETAILS?.order_status === "Delivered" ||
                                  ORDER_DETAILS?.order_status === "Completed" ||
                                  ORDER_DETAILS?.order_status === "Ready"
                                    ? "success-booking-one success-booking"
                                    : ""
                                }
                              >
                                <span className="track_circle"></span>
                                <span className="status_name">Pending</span>
                              </li>
                              {!orderType && (
                                <li
                                  className={
                                    ORDER_DETAILS?.order_status ===
                                      "Accepted" ||
                                    ORDER_DETAILS?.order_status ===
                                      "Picked-Up" ||
                                    ORDER_DETAILS?.order_status ===
                                      "Delivered" ||
                                    ORDER_DETAILS?.order_status ===
                                      "Completed" ||
                                    ORDER_DETAILS?.order_status === "Ready"
                                      ? "success-booking-one success-booking"
                                      : ""
                                  }
                                >
                                  <span className="track_circle"></span>
                                  <span className="status_name">Accepted</span>
                                </li>
                              )}
                              {!orderType &&
                                (ORDER_DETAILS?.mode === "Pickup" ? (
                                  <li
                                    className={
                                      ORDER_DETAILS?.order_status ===
                                        "Picked-Up" ||
                                      ORDER_DETAILS?.order_status ===
                                        "Delivered" ||
                                      ORDER_DETAILS?.order_status ===
                                        "Completed" ||
                                      ORDER_DETAILS?.order_status === "Ready"
                                        ? "success-booking-one success-booking"
                                        : ""
                                    }
                                  >
                                    <span className="track_circle"></span>
                                    <span className="status_name">Ready</span>
                                  </li>
                                ) : (
                                  <li
                                    className={
                                      ORDER_DETAILS?.order_status ===
                                        "Picked-Up" ||
                                      ORDER_DETAILS?.order_status ===
                                        "Delivered" ||
                                      ORDER_DETAILS?.order_status ===
                                        "Completed" ||
                                      ORDER_DETAILS?.order_status === "Ready"
                                        ? "success-booking-one success-booking"
                                        : ""
                                    }
                                  >
                                    <span className="track_circle"></span>
                                    <span className="status_name">
                                      Picked-Up
                                    </span>
                                  </li>
                                ))}
                              {ORDER_DETAILS?.mode === "Pickup" ? (
                                <li
                                  className={
                                    ORDER_DETAILS?.order_status ===
                                      "Completed" ||
                                    ORDER_DETAILS?.order_status ===
                                      "Delivered" ||
                                    ORDER_DETAILS?.order_status === "Picked-Up"
                                      ? // ORDER_DETAILS?.order_status === "Ready" ||
                                        "success-booking-one success-booking"
                                      : ""
                                  }
                                >
                                  <span className="track_circle"></span>
                                  <span className="status_name">Completed</span>
                                </li>
                              ) : (
                                <>
                                  {orderType ? (
                                    <li
                                      className={
                                        ORDER_DETAILS?.order_status ===
                                          "Completed" ||
                                        ORDER_DETAILS?.order_status ===
                                          "Delivered"
                                          ? "success-booking-one success-booking"
                                          : ""
                                      }
                                    >
                                      <span className="track_circle"></span>
                                      <span className="status_name">
                                        Completed
                                      </span>
                                    </li>
                                  ) : (
                                    <li
                                      className={
                                        ORDER_DETAILS?.order_status ===
                                          "Completed" ||
                                        ORDER_DETAILS?.order_status ===
                                          "Delivered"
                                          ? "success-booking-one success-booking"
                                          : ""
                                      }
                                    >
                                      <span className="track_circle"></span>
                                      <span className="status_name">
                                        Delivered
                                      </span>
                                    </li>
                                  )}
                                </>
                              )}
                            </ul>
                          </div>
                        </div>
                      )}
                      {orderType !== "eGiftCard" && (
                        <>
                          {ORDER_DETAILS?.product_summary?.length !== 0 &&
                            ORDER_DETAILS?.product_summary[0]
                              ?.products_image && (
                              <div className="map_space mt-4 mb-4">
                                <div className="border rounded p-3">
                                  <img
                                    src={
                                      ORDER_DETAILS?.product_summary[0]
                                        ?.products_image
                                    }
                                    alt="No_Image"
                                    className="w-100"
                                  />
                                </div>
                              </div>
                            )}
                        </>
                      )}
                    </Col>
                    {/* // )} */}
                  </Row>
                </div>
              </Container>
            ) : null}
          </>
        )}
      </section>
      <CancelOrderModal
        show={showCancelOrder}
        onHide={() => toggleCancelOrder()}
        handelCancelOrder={handelCancelOrder}
        cancelOrderLoader={cancelOrderLoader}
        errorMessage={errorMessage}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
};

export default OrderDetail;
