import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "../footer/footer.css";
import "../footer/footermedia.css";
import Appstore from "assets/images/app-store.png";
import Appstoremobile from "assets/images/play-storemobile.svg";
import googleStore from "assets/images/google-play.png";
import googleStoremobile from "assets/images/googleplaymobile.svg";

const Footer = () => {
  const location = useLocation();

  //ALL STATE
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth

  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  const scrollerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    bottom: "40px",
    right: "20px",
    background: "#F06F00",
    width: "50px",
    height: "50px",
    textDecoration: "none",
    borderRadius: "35px",
    transition: "all 0.3s ease",
    color: "#fff",
    zIndex: "999",
    cursor: "pointer",
  };
  return (
    <div>
      <footer>
        <div className="main-footer">
          <Container className="location-menus mt-0">
            <Row>
              <Col xs={6} sm={4}>
                <div className="footer-menu">
                  <h3>Contact & Links</h3>
                  <ul>
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/check-balance">Gift Cards</Link>
                    </li>
                    <li>
                      <Link to="/rewards">Rewards</Link>
                    </li>
                    <li>
                      {/* <Link to="/terms-to-use/2">Terms of Use</Link> */}
                      <Link to="/terms-of-use">Terms of Use</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQs</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={6} sm={4}>
                <div className="footer-menu">
                  <h3 className="">Partner With Us</h3>
                  <ul>
                    <li>
                      {/* <Link to="/driver-signup/4">Become A Driver</Link> */}
                      <Link to="/driver-signup">Become A Driver</Link>
                    </li>
                    <li>
                      {/* <Link to="/restaurant-signup/5">List a Restaurant</Link> */}
                      <Link to="/restaurant-signup">List a Restaurant</Link>
                    </li>
                    {/* <li>
                      <Link to="/career">Career</Link>
                    </li> */}
                  </ul>
                </div>
              </Col>
              <Col xs={10} sm={4}>
                <div className="footer-menu d-sm-block d-flex mb-2 store_tab">
                  <Link
                    to="https://apps.apple.com/us/app/food-dudes/id1180442819"
                    target="_blank"
                    className="d-block mb-sm-3 me-2 me-sm-0"
                  >
                    <img
                      src={Appstore}
                      alt="No_Image"
                      className="d-none d-sm-block"
                    />
                    <img
                      src={Appstoremobile}
                      alt="No_Image"
                      className="d-block d-sm-none"
                    />
                  </Link>
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.fddcustomer&hl=en-IN"
                    target="_blank"
                    className="d-block"
                  >
                    <img
                      src={googleStore}
                      alt="No_Image"
                      className="d-none d-sm-block"
                    />
                    <img
                      src={googleStoremobile}
                      alt="No_Image"
                      className="d-block d-sm-none"
                    />
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="copyright">
          © {currentYear} Food Dudes Delivery, LLC
        </div>
      </footer>

      {isVisible && location.pathname !== "/" && (
        <span
          onClick={ScrollToTop}
          style={scrollerStyle}
          className="top-scroller"
        >
          <i
            className="fa fa-arrow-up "
            onClick={ScrollToTop}
            aria-hidden="true"
          ></i>
        </span>
      )}
    </div>
  );
};

export default Footer;
