import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../header/header.css";
import "../header/headerMedia.css";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_CITY_SEO_DATA,
  GET_NOTIFICATION_EXIST,
  SHOW_SIDE_BAR,
} from "store/Action/HomeAction";
import NativeHeader from "./NativeHeader";
import AuthHeader from "./AuthHeader";
import {
  ASAP_DATE,
  GET_ASAP_ADVANCE_TYPE,
  GET_CITY_TIME_SLOT,
  GET_TIME,
} from "store/Action/RasturantAction";
import moment from "moment";
import { GET_DATE_TIME_ON_MENU } from "store/Action/AuthAction";
import { ACTIVE_MENU_WHEN_NOT_AVAILABLE } from "store/Action/RasturantMenuAction";
import { urls } from "routes/url";

const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const path = location.pathname.replace("/", "");
  var menuId = location.pathname?.replace(/^\D+/g, "");
  var menuUrl = location.pathname?.replace(menuId, "");

  //ALL SELECTORS
  const {
    VISIBLE_SIDEBAR,
    CART_LIST,
    DEFAULT_ADDRESS,
    SELECTED_RESTAURANT_DATA_STATE,
    ASAP_CURRENT_DATE,
    SERVICE_TYPE,
    PREVENT_RESTAURANT_Obj_STATE,
  } = useSelector((store) => ({
    VISIBLE_SIDEBAR: store.HomeReducer.sidebarVisible,
    SHOE_NOTIFICATION_POPUP: store?.HomeReducer?.showHeaderPopUp,
    CART_LIST: store?.RasturantMenuReducer?.cartList ?? [],
    ASAP_DATE_STATE: store?.ResturantReducer?.asapDate,
    DEFAULT_ADDRESS: store.ProfileReducer.defaultAddress,
    SELECTED_RESTAURANT_DATA_STATE:
      store?.ResturantReducer?.selectedRestaurantData ?? {},
    MENU_CART_SHOW: store?.RasturantMenuReducer?.menuCartShow ?? false,
    ASAP_CURRENT_DATE: store?.ResturantReducer?.asapDate,
    SERVICE_TYPE: store.ResturantReducer.serviceType,
    PREVENT_RESTAURANT_Obj_STATE:
      store?.RasturantMenuReducer?.restaurantPrvId ?? {},
  }));

  const restaurantId = SELECTED_RESTAURANT_DATA_STATE?.id
    ? SELECTED_RESTAURANT_DATA_STATE?.id
    : PREVENT_RESTAURANT_Obj_STATE?.id;

  const [dynamicUrl, setdynamicUrl] = useState("");
  useEffect(() => {
    setdynamicUrl(`/${SELECTED_RESTAURANT_DATA_STATE?.slug}`);
  }, [SELECTED_RESTAURANT_DATA_STATE?.slug]);

  const Token = localStorage.getItem("access_token");

  //ALL STATE
  const [sticky, setSticky] = useState("");
  const [sidebarShow, setSideBarShow] = useState(true);
  const [nativeHeader, setNativeHeader] = useState(true);
  const [headerCart, setHeaderCart] = useState(false);
  const [showHamburger, setShowHamburger] = useState(false);
  const [visibleFloatingMessage, setVisibleFloatingMessage] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;

    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 20 ? "fixed-top" : "";
    setSticky(stickyClass);
  };

  const classes = path
    ? location?.pathname == dynamicUrl
      ? `header-section scroller-header ${sticky}`
      : `header-section ${sticky}`
    : `header-section only-home ${sticky}`;

  const handelSidebarOpen = () => {
    dispatch(SHOW_SIDE_BAR(!VISIBLE_SIDEBAR));
  };

  useEffect(() => {
    const url = location?.pathname;
    const path = url.substring(1);
    // console.log("Url::",location)
    if (url !== "/restaurants") {
      dispatch(GET_CITY_SEO_DATA({}));
    }
    if (
      // url == "/sign-in" ||
      // url == "/sign-up" ||
      url == "/forgot-password" ||
      url == "/otp-verification" ||
      url == "/reset-password" ||
      url == "signup-otp-verification"
    ) {
      setSideBarShow(false);
    } else {
      setSideBarShow(true);
    }

    if (
      url == "/buy-gift-card" ||
      url == "/card-checkout" ||
      url == "/rewards" ||
      url == "/redeem-points" ||
      url == "/giftcard-checkout" ||
      url == "/giftcard" ||
      url == "/payment-method"
    ) {
      setShowHamburger(true);
      setVisibleFloatingMessage(false);
      // } else if (url == "/restaurants" || menuUrl == "/restaurants-menu/") {
    } else if (url == "/restaurants" || location?.pathname == dynamicUrl) {
      setShowHamburger(false);
      setVisibleFloatingMessage(true);
    } else if (location?.pathname !== dynamicUrl) {
      //**********NEW CODE */
      dispatch(ACTIVE_MENU_WHEN_NOT_AVAILABLE(""));
    } else {
      setShowHamburger(false);
      setVisibleFloatingMessage(false);
    }

    if (url === "/checkout") {
      setVisibleFloatingMessage(true);
    }

    if (urls.indexOf(path) === -1) {
      if (path.includes("order-detail")) {
        setNativeHeader(true);
      } else if (path.includes("address")) {
        setNativeHeader(true);
      } else if (path.includes("social-signup")) {
        setNativeHeader(true);
      } else if (path.includes("login-otp-verification")) {
        setNativeHeader(true);
      } else if (path.includes("master-login")) {
        setNativeHeader(true);
      } else {
        setNativeHeader(false);
      }
    } else {
      setNativeHeader(true);
    }
  }, [location?.pathname, dynamicUrl]);

  const handleToggleHeaderCart = () => {
    setHeaderCart(!headerCart);
  };

  useEffect(() => {
    if (CART_LIST.length == 0) {
      setHeaderCart(false);
    }
  }, [CART_LIST]);

  useEffect(() => {
    if (Token) {
      fetchNotificationExist();
    }
  }, [Token]);

  const fetchNotificationExist = () => {
    if (Token) {
      dispatch(
        GET_NOTIFICATION_EXIST({}, (res, loading, error) => {
          if (!error) {
          }
        })
      );
    }
  };

  useEffect(() => {
    if (
      ASAP_CURRENT_DATE &&
      Object.keys(DEFAULT_ADDRESS)?.length &&
      DEFAULT_ADDRESS.entry_city
    ) {
      const page = window.location.pathname;
      const params = {
        city: DEFAULT_ADDRESS.entry_city,
        date: moment(ASAP_CURRENT_DATE).format("YYYY-MM-DD"),
        service_type: SERVICE_TYPE,
        restaurant_id: page !== "/restaurants" ? restaurantId : "",
        customer_lat: DEFAULT_ADDRESS?.address_lat ?? "",
        customer_lng: DEFAULT_ADDRESS?.address_lng ?? "",
        zipcode: DEFAULT_ADDRESS?.entry_postcode ?? "",
      };
      dispatch(
        GET_CITY_TIME_SLOT({ params }, (res, loading, error) => {
          if (res?.is_previous_date) {
            dispatch(GET_ASAP_ADVANCE_TYPE(1));
            dispatch(ASAP_DATE(""));
            dispatch(GET_TIME({}));
            dispatch(GET_DATE_TIME_ON_MENU(""));
          }
        })
      );
    }
  }, []);

  return (
    <div>
      <>
        {nativeHeader ? (
          <NativeHeader
            classes={classes}
            handelSidebarOpen={handelSidebarOpen}
            sidebarShow={sidebarShow}
            path={path}
            handleToggleHeaderCart={handleToggleHeaderCart}
            setHeaderCart={setHeaderCart}
            headerCart={headerCart}
            showHamburger={showHamburger}
            visibleFloatingMessage={visibleFloatingMessage}
          />
        ) : (
          <AuthHeader
            classes={classes}
            handelSidebarOpen={handelSidebarOpen}
            sidebarShow={sidebarShow}
            path={path}
            url={menuUrl}
            handleToggleHeaderCart={handleToggleHeaderCart}
            setHeaderCart={setHeaderCart}
            headerCart={headerCart}
            showHamburger={showHamburger}
            visibleFloatingMessage={visibleFloatingMessage}
            dynamicUrl={dynamicUrl}
          />
        )}
      </>
    </div>
  );
};

export default Header;
