import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import Driver from "assets/images/driver.png";
import PickUp from "assets/images/pickup.png";
import DropOff from "assets/images/dropoff.png";
import "./OrderTrack.css";
import "./OrderTrackNew.css";
import ReactHelmet from "components/shared/ReactHelmet/ReactHelmet";
import NotFound from "components/shared/Loader/NotFound";
import Logo from "assets/images/logo-new.webp";
import CheckBox from "assets/images/checkmark (1).png";
import { useDispatch } from "react-redux";
import { GET_ORDER_TRACKING } from "store/Action/Sidebar/OrderAction";
import moment from "moment";
import OrderDetail from "../orderDetail/OrderDetail";
import restaurantImg from "assets/images/cuisines8.jpg";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useLocation, useNavigate } from "react-router-dom";

const OrderTrackNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const isMobileApp = query.get("is_mobile_app");

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCJOYrk0ia3CDwLnlPvUV6-LGPk9PcsiOw",
    version: "3.47",
    libraries: ["geometry", "places", "roads"],
  });

  const paths = window.location.pathname.split("/");
  let OrderId = paths[paths.length - 1];
  const isMobile = window.innerWidth <= 768;
  const mapStyles = {
    height: isMobile ? "100vh" : "100vh",
    width: "100%",
  };

  const [driverPosition, setDriverPosition] = useState(null);
  const [routePath, setRoutePath] = useState([]);
  const [orderDetail, setOrderDetails] = useState({});
  const [isApiLoading, setApiLoading] = useState(true);
  const [pickupHover, setPickupHover] = useState(false);
  const [dropoffHover, setDropoffHover] = useState(false);
  const [visiblePhoneNo, setVisiblePhoneNo] = useState(false);
  const [bottomPanelShow, setBottomPanelShow] = useState(false);
  const [infoWindowOpenPickupMobile, setInfoWindowOpenPickupMobile] =
    useState(false);
  const [infoWindowOpenDropOffMobile, setInfoWindowOpenDropOffMobile] =
    useState(false);

  useEffect(() => {
    if (isMobileApp == "yes") {
      const handleBackButton = (event) => {
        event.preventDefault();
        if (window.history.length > 1) {
          navigate(-1);
        } else {
          if (window.confirm("Do you want to exit the app?")) {
            window.close();
          }
        }
      };

      document.addEventListener("backbutton", handleBackButton);

      return () => {
        document.removeEventListener("backbutton", handleBackButton);
      };
    }
  }, [navigate]);

  const handleTogglePanel = () => {
    setBottomPanelShow(!bottomPanelShow);
  };

  useEffect(() => {
    if (OrderId) {
      getOrderDetails();
    }
  }, [OrderId]);

  const getOrderDetails = () => {
    dispatch(
      GET_ORDER_TRACKING(OrderId, async (res, loading, error) => {
        setApiLoading(loading);
        if (!error && res?.data) {
          const data = res.data;
          setOrderDetails(data);

          // Convert driver_route into LatLng objects
          if (data?.driver_route) {
            const route = data?.driver_route?.map(([lat, lng]) => ({
              lat: parseFloat(lat),
              lng: parseFloat(lng),
            }));
            // snapToRoads(route);
            const snappedRoute = await fetchSnappedPath(route);
            setRoutePath(snappedRoute || route);
            // setRoutePath(route);
          }

          // Set initial driver position if available
          if (data?.driver_lat && data?.driver_lng) {
            setDriverPosition({
              lat: parseFloat(data?.driver_lat),
              lng: parseFloat(data?.driver_lng),
            });
          }
        }
      })
    );
  };

  // Fetch snapped path from Google Roads API
  const fetchSnappedPath = async (path) => {
    const apiKey = "AIzaSyCJOYrk0ia3CDwLnlPvUV6-LGPk9PcsiOw";
    const pathParam = path.map(({ lat, lng }) => `${lat},${lng}`).join("|");
    const url = `https://roads.googleapis.com/v1/snapToRoads?path=${pathParam}&interpolate=true&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data?.snappedPoints) {
        return data?.snappedPoints?.map((point) => ({
          lat: point.location.latitude,
          lng: point.location.longitude,
        }));
      }
    } catch (error) {
      console.error("Error fetching snapped path:", error);
    }

    return null;
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (OrderId && orderDetail?.order_status !== "Delivered") {
  //       getOrderDetails();
  //     }
  //   }, 10000);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (OrderId && orderDetail?.order_status !== "Delivered") {
        getOrderDetails();
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, [OrderId, orderDetail?.order_status]);

  const handleOpenDiler = (data) => {
    if (data?.driver_phone) {
      window.location.href = `tel:${data?.driver_phone}`;
    }
  };

  useEffect(() => {
    const tawk = document.createElement("script");
    tawk.async = true;
    tawk.src = "https://embed.tawk.to/54fa63c38ac92cc0041d1a42/default";
    tawk.charset = "UTF-8";
    tawk.setAttribute("crossorigin", "*");

    document.body.appendChild(tawk);

    tawk.onload = () => {
      if (window.Tawk_API) {
        console.log("Loaded2");
        window.Tawk_API.showWidget();
      }
    };
    /* return () => {
      document.body.removeChild(tawk);
    }; */
  }, [orderDetail]);

  const DropOffIcon = {
    url: DropOff,
    scaledSize: new window.google.maps.Size(60, 60),
  };
  const PickUpIcon = {
    url: PickUp,
    scaledSize: new window.google.maps.Size(60, 60),
  };

  return (
    <div>
      <div className="main-container mt-0 main-container-new">
        <ReactHelmet />
        <section className="order-detail-sec" style={{ padding: "0px" }}>
          {!isApiLoading && !orderDetail && (
            <div className="row">
              <NotFound height={250} width={250} />
            </div>
          )}
          {orderDetail && (
            <>
              <div className="row order p-0 m-0 order-track-new">
                <div
                  className={`col-md-5 order-detail-left ${
                    bottomPanelShow ? "showPanel" : ""
                  }`}
                >
                  <div
                    className="order-head position-relative "
                    onClick={() => handleTogglePanel()}
                  >
                    <img src={Logo} style={{ width: "150px" }} alt="Logo" />
                    <button
                      type="button"
                      className="d-sm-none d-block top-down-arrow bg-transparent border-0 p-0 position-absolute right-0"
                    >
                      {bottomPanelShow ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </button>
                  </div>
                  <div className="oder-contant">
                    <p className="order-content-new d-flex justify-content-center align-item-center">
                      {orderDetail?.delivery_time_message}
                    </p>
                    <p
                      className={`badge-one ${
                        orderDetail?.is_order_delivered === "2" ||
                        orderDetail?.is_order_delivered === "3"
                          ? "red-badge"
                          : ""
                      }`}
                    >
                      {orderDetail?.is_order_delivered === "1" && (
                        <img src={CheckBox} alt="Delivered" />
                      )}
                      {orderDetail?.order_status}
                    </p>

                    {/* <div className="progress-bar-track"></div> */}

                    <div className="order-address">
                      <div className="border-bottom">
                        <p className="order-lebal">Order ID</p>
                        <p className="order-text">{orderDetail?.orders_id}</p>
                      </div>
                      <div className="border-bottom mt-3 mb-3 pb-3 position-relative order-track-outer">
                        <div className="d-flex address-box address-track-new">
                          <div className="address-icon">
                            <img src={PickUp} alt="PickUp" />
                          </div>
                          <div className="address-left-wrapper">
                            <p
                              className="order-lebal"
                              style={{ fontWeight: "500", marginBottom: "0px" }}
                            >
                              Pickup Address
                            </p>
                            <p className="order-text">
                              {orderDetail?.pickup_address}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex address-box address-track-new">
                          <div className="address-icon">
                            <img src={DropOff} alt="DropOff" />
                          </div>
                          <div className="address-left-wrapper">
                            <p
                              className="order-lebal"
                              style={{ fontWeight: "500", marginBottom: "0px" }}
                            >
                              Dropoff Address
                            </p>
                            <p className="order-text mb-0">
                              {orderDetail?.dropoff_address}
                            </p>
                          </div>
                        </div>
                        {/* <div className="dot-line"></div> */}
                      </div>
                      {orderDetail?.driver_name &&
                        orderDetail?.order_status !== "Canceled" && (
                          <div className="border-bottom mt-3 mb-3 pb-3 d-flex justify-content-between align-items-center position-relative driver-info ">
                            <div className="d-flex direction-row address-box flex-column">
                              <p
                                className="order-lebal driver-font"
                                style={{
                                  fontWeight: "500",
                                  marginBottom: "0px",
                                }}
                              >
                                Driver
                              </p>
                              <p
                                style={{ marginBottom: "0px" }}
                                className="driver-font"
                              >
                                {orderDetail?.driver_name}
                              </p>

                              {/* {orderDetail?.vehicle && (
                              <p
                                className="order-lebal"
                                style={{
                                  fontWeight: "500",
                                  marginBottom: "20px",
                                }}
                              >
                                {orderDetail?.vehicle}
                              </p>
                            )} */}
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                              <div
                                className="d-flex justify-content-center mb-2 align-items-center custom-driver-box"
                                onClick={() =>
                                  setVisiblePhoneNo(!visiblePhoneNo)
                                }
                              >
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"
                                  viewBox="0 0 16 16"
                                  color="#0062FF"
                                  className="styles_phoneIcon__3kfvQ"
                                  height="30px"
                                  width="30px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ color: "rgb(0, 98, 255)" }}
                                >
                                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"></path>
                                </svg>
                              </div>
                              {visiblePhoneNo && (
                                <div
                                  onClick={() => handleOpenDiler(orderDetail)}
                                >
                                  <p
                                    style={{
                                      marginBottom: "0px",
                                      marginLeft: "10px",
                                    }}
                                    className="driver-font"
                                  >
                                    {`+1${orderDetail?.driver_phone}`}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                      {/* <div className="restaurnt-img-track mt-sm-5 mt-3">
                        <figure>
                          <img src={restaurantImg} alt="" />
                        </figure>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div
                  className={"col-md-7 order-detail-right d-none d-sm-block"}
                >
                  {/* <div className={"col-md-7 order-detail-right"}> */}
                  {isLoaded && (
                    <GoogleMap
                      mapContainerStyle={mapStyles}
                      zoom={14}
                      center={driverPosition || routePath[0]}
                      options={{
                        draggable: true,
                        disableDoubleClickZoom: false,
                        zoomControl: false,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        panControl: false,
                        rotateControl: false,
                        fullscreenControl: false,
                      }}
                    >
                      <Polyline
                        path={routePath}
                        options={{
                          strokeColor: "#0F53FF",
                          strokeOpacity: 1,
                          strokeWeight: 5,
                        }}
                      />
                      {driverPosition &&
                        orderDetail?.order_status !== "Delivered" && (
                          <Marker position={driverPosition} icon={Driver} />
                        )}
                      <Marker
                        position={routePath[0]}
                        icon={PickUpIcon}
                        // label="Pickup"
                        onMouseOver={() => setPickupHover(true)}
                        onMouseOut={() => setPickupHover(false)}
                      />
                      {pickupHover && (
                        <InfoWindow
                          position={routePath[0]}
                          onCloseClick={() => setPickupHover(false)}
                          options={{
                            pixelOffset: new window.google.maps.Size(0, -40),
                          }}
                        >
                          <div
                            className="info-window-track"
                            style={{
                              background: "#fff",
                              padding: "10px",
                              borderRadius: "4px",
                              boxShadow: "0 0 14px 0 rgba(0,0,0,.37)",
                            }}
                          >
                            <p
                              style={{ fontWeight: "bold" }}
                              className="picking-track-address mb-2"
                            >
                              Pickup Address:
                            </p>
                            <p style={{ margin: 0 }}>
                              {orderDetail?.pickup_address}
                            </p>
                          </div>
                        </InfoWindow>
                      )}

                      <Marker
                        position={routePath[routePath.length - 1]}
                        // icon={DropOff}
                        icon={DropOffIcon}
                        // label="Dropoff"
                        onMouseOver={() => setDropoffHover(true)}
                        onMouseOut={() => setDropoffHover(false)}
                      />
                      {dropoffHover && (
                        <InfoWindow
                          position={routePath[routePath.length - 1]}
                          onCloseClick={() => setDropoffHover(false)}
                          options={{
                            pixelOffset: new window.google.maps.Size(0, -40),
                            zIndex: 1000,
                          }}
                        >
                          <div
                            className="info-window-track"
                            style={{
                              background: "#fff",
                              padding: "10px",
                              borderRadius: "4px",
                              boxShadow: "0 0 14px 0 rgba(0,0,0,.37)",
                            }}
                          >
                            <p
                              style={{ fontWeight: "bold" }}
                              className="picking-track-address mb-2"
                            >
                              Dropoff Address:
                            </p>
                            <p style={{ margin: 0 }}>
                              {orderDetail?.dropoff_address}
                            </p>
                          </div>
                        </InfoWindow>
                      )}
                    </GoogleMap>
                  )}
                </div>
              </div>

              {/* map-for-mobile */}

              <div className="d-block d-sm-none">
                {isLoaded && (
                  <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={14}
                    center={driverPosition || routePath[0]}
                    options={{
                      draggable: true,
                      disableDoubleClickZoom: false,
                      zoomControl: false,
                      mapTypeControl: false,
                      scaleControl: false,
                      streetViewControl: false,
                      panControl: false,
                      rotateControl: false,
                      fullscreenControl: false,
                    }}
                  >
                    <Polyline
                      path={routePath}
                      options={{
                        strokeColor: "#0F53FF",
                        strokeOpacity: 1,
                        strokeWeight: 5,
                      }}
                    />
                    {driverPosition &&
                      orderDetail?.order_status !== "Delivered" && (
                        <Marker position={driverPosition} icon={Driver} />
                      )}
                    <Marker
                      position={routePath[0]}
                      icon={PickUpIcon}
                      onClick={() =>
                        setInfoWindowOpenPickupMobile(
                          !infoWindowOpenPickupMobile
                        )
                      }
                    />
                    {infoWindowOpenPickupMobile && (
                      <InfoWindow
                        position={routePath[0]}
                        onCloseClick={() =>
                          setInfoWindowOpenPickupMobile(false)
                        }
                        options={{
                          pixelOffset: new window.google.maps.Size(0, -40),
                        }}
                      >
                        <div
                          className="info-window-track"
                          style={{
                            background: "#fff",
                            padding: "10px",
                            borderRadius: "4px",
                            boxShadow: "0 0 14px 0 rgba(0,0,0,.37)",
                          }}
                        >
                          <p
                            style={{ fontWeight: "bold" }}
                            className="picking-track-address mb-2"
                          >
                            Pickup Address:
                          </p>
                          <p style={{ margin: 0 }}>
                            {orderDetail?.pickup_address}
                          </p>
                        </div>
                      </InfoWindow>
                    )}

                    <Marker
                      position={routePath[routePath.length - 1]}
                      icon={DropOffIcon}
                      onClick={() =>
                        setInfoWindowOpenDropOffMobile(
                          !infoWindowOpenDropOffMobile
                        )
                      }
                    />
                    {infoWindowOpenDropOffMobile && (
                      <InfoWindow
                        position={routePath[routePath.length - 1]}
                        onCloseClick={() =>
                          setInfoWindowOpenDropOffMobile(false)
                        }
                        options={{
                          pixelOffset: new window.google.maps.Size(0, -40),
                          zIndex: 1000,
                        }}
                      >
                        <div
                          className="info-window-track"
                          style={{
                            background: "#fff",
                            padding: "10px",
                            borderRadius: "4px",
                            boxShadow: "0 0 14px 0 rgba(0,0,0,.37)",
                          }}
                        >
                          <p
                            style={{ fontWeight: "bold" }}
                            className="picking-track-address mb-2"
                          >
                            Dropoff Address:
                          </p>
                          <p style={{ margin: 0 }}>
                            {orderDetail?.dropoff_address}
                          </p>
                        </div>
                      </InfoWindow>
                    )}
                    {/* {driverPosition && (
                      <Marker position={driverPosition} icon={Driver} />
                    )}

                    <Marker
                      position={routePath[0]}
                      icon={PickUp}
                    />

                    <Marker
                      position={routePath[routePath.length - 1]}
                      icon={DropOff}
                    /> */}
                  </GoogleMap>
                )}
              </div>

              {/* map-for-mobile */}
            </>
          )}
        </section>
      </div>
    </div>
  );
};

export default OrderTrackNew;
